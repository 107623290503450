/* eslint-disable no-unused-vars */
export { default as findMostCommonOccurence } from './findMostCommonOccurence'
export { default as isSaveAndExportEnabled } from './isSaveAndExportEnabled'
export { default as getQueryFromObj } from './getQueryFromObj'
export { default as getRandomId } from './getRandomId'
export { isMobileDevice } from './isMobileDevice'


export function isObjEmpty (payload = {}) {
  return !payload || payload.constructor === Object && Object.entries(payload).length === 0
}

export function isNullOrUndeclared (value) {
  return typeof value === "undefined" || (typeof value === "object" && !value)
}

export const asyncSleep = (timeout = 0) => new Promise((r) => setTimeout(r, timeout)) 


export function isIncluding (first = '', second = '') {
  try {
    return first.toUpperCase().includes(second.toUpperCase())
  } 
  catch (error) {
    return false
  }
}

export const getQueryObjectFromWindowLocation = () => {
  // Original version
  // https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
  const search = location.search.substring(1)
  
  try {
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', (key, value) => key === "" ? value : decodeURIComponent(value))
  }

  catch (error) {
    return {}
  }
}

export function renderQueryFromObject (payload = {}) {
  try {
    if (isObjEmpty(payload)) {
      return ''
    }

    const getCommandValuesAsQuery = (targetObject = {}) => Object
      .entries(targetObject)
      .reduce((result, [targetKey, {key, value} = {}], index) => `${ result }${ targetKey }${ key }${ value }${ index < Object.entries(targetObject).length - 1 ? ';' : '' }`, '')  

    return Object
      .entries(payload)
      .reduce((result, [commandKey, commandValues], currentIndex) => {
        return `${ result }${ currentIndex > 0 ? ';' : '' }${ commandKey }=${ getCommandValuesAsQuery(commandValues) }`
      }, '')
  }

  catch (error) {
    return ''
  }
}

export function reduceArrayToObject (
  targetArray = [], 
  defineItemKeyFn = null, 
  includeDataToObject = (obj) => obj
) {
  try {
    if (!Array.isArray(targetArray) || !targetArray.length) {
      return {}
    }

    if (isNullOrUndeclared(defineItemKeyFn) || typeof defineItemKeyFn !== "function") {
      console.error('@reduceArrayToObject | Must include defineItemKeyFn as a function')
      return {}
    }

    return targetArray  
      .reduce((result, oneItem) => ({ 
        ...result, 
        [defineItemKeyFn(oneItem)]: includeDataToObject(oneItem)
      }), {})
  }

  catch (error) {
    return {}
  }
}

export const capitalize = (str) => {
  if (typeof str !== 'string') {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Adds 2 numbers and returns sum with maximum 2 decimal spaces after comma
 * @param {number} value1
 * @param {number} value2
 */
export const safeAdd = (value1, value2) => {
  return Number((Number(value1 || 0) + Number(value2 || 0)).toFixed(2))
}


export const compareObjAgainstTarget = (obj_1 = {}, obj_2 = {}) => {
  try {   
    return Object
      .entries(obj_1)
      .every(([key, value]) => {
        if (obj_2[key] === null && [null, undefined, ''].includes(value)) {
          return true
        }

        return obj_2[key] === value
      })
  }
  
  catch (error) {
    return false  
  }
}