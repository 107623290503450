import Axios from 'axios'
import { PRICING_PLAN } from '@/enums'
import { setReceiptCustomTripId } from '../trips'

function load(companyId, receiptId) {
  const getURL = `@coreapi/companies/${companyId}/receipts/${receiptId}?itemrows=true`
  return Axios.get(getURL).then(({ data: receipt = {} }) => receipt)
}

/**
 * Creates a new receipt
 */
function create(companyId, tripId, extras) {
  var initialData = {}
  // it would be correct to check against company's (integration) settings

  if (isNaN(tripId)) {
    // TTU REPORTS
    initialData.extraData = {}
    initialData.extraData.uid = tripId
  } else if (tripId > 0) {
    // NORMAL REPORTS
    initialData.tripId = tripId
  }

  if (extras) {
    Object.assign(initialData, extras)
  }

  const { company = {} } = extras || {}

  if (company.monthlyThreshold5Exceeded && company.planId == PRICING_PLAN.FREE_DEMO) {
    return Promise.reject('monthlyThreshold5Exceeded')
  }

  var createURL = this.$config.services.coreapi.url + '/companies/' + companyId + '/receipts?itemrows=true'
  return Axios.post(createURL, initialData).then(function (result) {
    var receipt = result.data
    return receipt
  })
}

/**
 * Updates the receipt
 */
function update(companyId, receiptId, key, value, callback) {
  var payload = {
    [key]: value
  }

  var uri = `@coreapi/companies/${companyId}/receipts/${receiptId}?itemrows=true`
  Axios.put(uri, payload)
    .then((edit) => {
      callback(null, edit.data)
    })
    .catch((error) => {
      callback(error)
    })
}

/**
 * UPDATE RECEIPT DATA BY OBJECT
 * @param {number} companyId
 * @param {number} receiptId
 * @param {object} payload
 * @returns {function} axios call
 */
const massUpdate = function (companyId, receiptId, payload) {
  return new Promise((resolve, reject) => {
    if (!companyId || !receiptId) {
      return reject(
        `Sorry, something went wrong... Create screenshot for developers [companyId: ${companyId}, receiptId: ${receiptId}]`
      )
    }
    // HERE GOES EXTRA LOGIC IF NEEDED

    const URI = '@coreapi/companies/' + companyId + '/receipts/' + receiptId

    Axios.put(URI, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * DELETE RECEIPT
 * @param {number} companyId
 * @param {number} receiptId
 * @returns {function} axios call
 */
const deleteReceipt = function (companyId, receiptId) {
  return new Promise((resolve, reject) => {
    if (!companyId || !receiptId) {
      return reject(
        `Sorry, something went wrong... Create screenshot for developers [companyId: ${companyId}, receiptId: ${receiptId}]`
      )
    }
    // HERE GOES EXTRA LOGIC IF NEEDED

    const URI = this.$config.services.coreapi.url + '/companies/' + companyId + '/receipts/' + receiptId

    Axios.delete(URI)
      .then(resolve)
      .catch(() => reject('somethingWentWrong'))
  })
}

function addToTrip(receipt, trip, callback) {
  if (trip._custom) {
    return setReceiptCustomTripId.call(this, receipt, trip, callback)
  }

  // default to regular update
  update.call(this, receipt.companyId, receipt.id, 'tripId', trip.id, callback)
}

export { load, create, update, addToTrip, massUpdate, deleteReceipt }

export default {
  load,
  create,
  update,
  addToTrip,
  massUpdate,
  deleteReceipt
}
