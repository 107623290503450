import { computed } from 'vue'
import moment from 'moment'
import { useDateFilters } from '@/composables'
import { SETTING_STORE_GETTERS, SETTING_STORE_ACTIONS, DATE_FILTER_SELECTIONS, NAVIGATION_TAB } from '@/enums'

import { xstore } from '@/store'
import { ActiveDates } from '@/models'

export default function useActiveDates() {
  const { selectableDates } = useDateFilters()

  const activeDates = computed<any | undefined>(() => {
    return xstore.getters[SETTING_STORE_GETTERS.ACTIVE_DATES]
  })

  const selectReceiptsBy = computed<string>(() => {
    return xstore.getters[SETTING_STORE_GETTERS.SELECT_RECEIPTS_BY]
  })

  const selectTripsBy = computed<string>(() => {
    return xstore.getters[SETTING_STORE_GETTERS.SELECT_TRIPS_BY]
  })

  const setActiveDates = (dates: ActiveDates) => {
    xstore.dispatch(SETTING_STORE_ACTIONS.UPDATE_ACTIVE_DATES, dates)

    if (dates?.target) {
      savePresetPereference(dates.target)
    }
  }

  const savePresetPereference = (preset: string) => {
    if (preset == DATE_FILTER_SELECTIONS.CUSTOM) {
      sessionStorage.setItem('startDate', activeDates.value.startDate)
      sessionStorage.setItem('endDate', activeDates.value.endDate)
    } else {
      localStorage.setItem('dateRangePreference', preset)
      sessionStorage.removeItem('startDate')
      sessionStorage.removeItem('endDate')
    }
  }

  const selectPresetDates = () => {
    const sessionStartDate = sessionStorage.getItem('startDate')
    const sessionEndDate = sessionStorage.getItem('endDate')
    if (sessionStartDate && sessionEndDate) {
      return {
        startDate: sessionStartDate,
        endDate: sessionEndDate
      }
    }

    const dateRangePreference = localStorage.getItem('dateRangePreference')
    if (dateRangePreference) {
      // @ts-ignore
      const preset = selectableDates[dateRangePreference]
      return {
        startDate: moment(preset.selectedDates.start).format('YYYY-MM-DD'),
        endDate: moment(preset.selectedDates.end).format('YYYY-MM-DD')
      }
    }

    return {
      startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  }

  const setDateSelectCriteria = (criteria: string, documentType: string) => {
    if (documentType === NAVIGATION_TAB.RECEIPTS) {
      xstore.dispatch(SETTING_STORE_ACTIONS.UPDATE_SELECT_RECEIPTS_BY, criteria)
      localStorage.setItem('selectReceiptsBy', criteria)
    }
    if (documentType === NAVIGATION_TAB.REPORTS) {
      xstore.dispatch(SETTING_STORE_ACTIONS.UPDATE_SELECT_TRIPS_BY, criteria)
      localStorage.setItem('selectTripsBy', criteria)
    }
  }

  return {
    activeDates,
    selectReceiptsBy,
    selectTripsBy,
    setActiveDates,
    setDateSelectCriteria,
    selectPresetDates
  }
}
