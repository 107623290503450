import { SORT_KEYS, STATUS_SEQUENCE, TRANSACTION_WARNING } from '@/enums'
import { receiptStatuses } from '@/lib/statuses/receiptStatuses'
import { useCompanies, useTripStatus, useCostTypes } from '@/composables'
import { ApprovalItem, Company, PaymentTransaction, Receipt, Trip } from '@/models'

export default function useTableSort() {
  const { statusInfo: tripStatus } = useTripStatus()
  const { activeCompany } = useCompanies()
  const { setCostTypeName } = useCostTypes()

  const sortByDateNewerToOlder = (itemA: any, itemB: any, sortDirection: 'ascending' | 'descending') => {
    const dateA = new Date(itemA.createdAt).getTime()
    const dateB = new Date(itemB.createdAt).getTime()

    if (sortDirection === 'descending') {
      return dateA - dateB // Sort from oldest to newest
    } else {
      return dateB - dateA // Sort from newest to oldest (default behavior)
    }
  }

  const transactionStatus = (transaction: PaymentTransaction) => {
    if (transaction.warning?.codename) {
      return TRANSACTION_WARNING[transaction.warning.codename as keyof typeof TRANSACTION_WARNING]
    } else if (transaction.receiptId) {
      return 'AAAAAA' // to differentiate matched and unmatched docs
    } else {
      return 'ZZZZZZ' // to differentiate matched and unmatched docs
    }
  }

  const sortReceiptsByStatusIndex =
    (sortDirection: 'ascending' | 'descending') => (receiptA: Receipt, receiptB: Receipt) => {
      const aStatuses = receiptStatuses(receiptA, activeCompany.value)
      const bStatuses = receiptStatuses(receiptB, activeCompany.value)
      const aIndex = aStatuses[1]?.index || aStatuses[0]?.index || STATUS_SEQUENCE.NONE
      const bIndex = bStatuses[1]?.index || bStatuses[0]?.index || STATUS_SEQUENCE.NONE

      if (aIndex === bIndex) {
        return sortByDateNewerToOlder(receiptA, receiptB, sortDirection)
      }
      return aIndex - bIndex
    }

  const sortByKey = (
    company: Company,
    sortableArray: Array<Receipt | Trip | PaymentTransaction | ApprovalItem>,
    sortable: 'receipts' | 'reports' | 'transactions' | 'approvals',
    sortKey: string,
    sortDirection: 'ascending' | 'descending'
  ) => {
    let sorted = [...sortableArray]

    if (!sortKey) {
      return sorted
    }

    if (sortKey === SORT_KEYS.SUBMITTER && sortable === 'approvals') {
      sorted = (sortableArray as ApprovalItem[]).sort((a: ApprovalItem, b: ApprovalItem) => {
        let first = a.data?.trip?.submitter?.fullName || a.data?.receipt?.submitter?.fullName || ''
        let second = b.data?.trip?.submitter?.fullName || b.data?.receipt?.submitter?.fullName || ''

        if (first === second) {
          return sortByDateNewerToOlder(a, b, sortDirection)
        }
        return first.localeCompare(second)
      })
    } else if (sortKey === SORT_KEYS.SUBMITTER) {
      sorted = sortableArray.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
        let first = a.submitter?.fullName || a.submitter?.email || ''
        let second = b.submitter?.fullName || b.submitter?.email || ''

        if (first === second) {
          return sortByDateNewerToOlder(a, b, sortDirection)
        }
        return first.localeCompare(second)
      })
    } else if (sortKey === SORT_KEYS.STATUS && sortable === 'receipts') {
      // @ts-ignore
      sorted = sortableArray.sort(sortReceiptsByStatusIndex(sortDirection))
    } else if (sortKey === SORT_KEYS.STATUS && sortable === 'reports') {
      sorted = sortableArray.sort((a, b) => tripStatus(a, company).index - tripStatus(b, company).index)
    } else if (sortKey === SORT_KEYS.CARDLFD && sortable === 'transactions') {
      sorted = (sortableArray as PaymentTransaction[]).sort(
        (a: PaymentTransaction, b: PaymentTransaction) =>
          Number(a.paymentMethod?.cardLfd) - Number(b.paymentMethod?.cardLfd)
      )
    } else if (sortKey === SORT_KEYS.NAME && sortable === 'transactions') {
      sorted = (sortableArray as PaymentTransaction[]).sort((a: PaymentTransaction, b: PaymentTransaction) => {
        const nameA = a.trip?.name || ''
        const nameB = typeof b.trip?.name === 'string' ? b.trip.name : ''
        return nameA.localeCompare(nameB)
      })
    } else if (sortKey === SORT_KEYS.STATUS && sortable === 'transactions') {
      sorted = (sortableArray as PaymentTransaction[]).sort((a, b) =>
        transactionStatus(a).localeCompare(transactionStatus(b))
      )
    } else if (sortKey === SORT_KEYS.DOCUMENT_TYPE && sortable === 'approvals') {
      sorted = (sortableArray as ApprovalItem[]).sort((a: ApprovalItem, b: ApprovalItem) => {
        const docTypeA = a.data?.trip?.subtype || a.data?.trip?.type || ''
        const docTypeB = b.data?.trip?.subtype || b.data?.trip?.type || ''

        return docTypeA?.localeCompare(docTypeB)
      })
    } else if (sortKey === SORT_KEYS.DOCUMENT && sortable === 'approvals') {
      sorted = (sortableArray as ApprovalItem[]).sort((a: ApprovalItem, b: ApprovalItem) => {
        const documentA = a.data?.trip?.name || a.data?.receipt?.supplier || ''
        const documentB = b.data?.trip?.name || b.data?.receipt?.supplier || ''

        return documentA?.localeCompare(documentB)
      })
    } else if (sortKey === SORT_KEYS.SUM && sortable === 'approvals') {
      sorted = (sortableArray as ApprovalItem[]).sort(
        (a: ApprovalItem, b: ApprovalItem) => (Number(a.data?.receipt?.sum) || 0) - (Number(b.data?.receipt?.sum) || 0)
      )
    } else if (sortKey === SORT_KEYS.EXPENSE_TYPE) {
      sorted = (sortableArray as Receipt[]).sort((a, b) => {
        const first = setCostTypeName(a, localStorage.language) || ''
        const second = setCostTypeName(b, localStorage.language) || ''
        return first?.localeCompare(second)
      })
    } else {
      sorted = sortableArray.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
        let first = a[sortKey] ?? ''
        let second = b[sortKey] ?? ''

        if (first === second) {
          return sortByDateNewerToOlder(a, b, sortDirection)
        }

        if (sortKey === SORT_KEYS.SUM) {
          first = Number(first) || 0
          second = Number(second) || 0
          return first - second
        }
        return first.localeCompare(second)
      })
    }

    if (sortDirection === 'descending') {
      sorted.reverse()
    }

    return sorted
  }

  const setSortToLocalstorage = (tableName: string, key: string, direction: string) => {
    localStorage.setItem(tableName + 'SortKey', key)
    localStorage.setItem(tableName + 'SortDirection', direction)
  }

  const getSortFromLocalstorage = (tableName: string) => {
    const localSortKey = localStorage?.getItem(tableName + 'SortKey') || ''
    const localSortDirection = localStorage?.getItem(tableName + 'SortDirection') || ''

    return { key: localSortKey, direction: localSortDirection }
  }

  return {
    sortByKey,
    sortReceiptsByStatusIndex,
    setSortToLocalstorage,
    getSortFromLocalstorage
  }
}
