import Axios from 'axios'

export default function useReceiptCRUD() {
  const createReceipt = async (companyId: any, tripId: any, extras: any) => {
    const initialPayload: any = {}

    // TEMPORARY TALTECH SOLUTION, SHOULD BE REMOVABLE AFTER 21.05.2024
    if (isNaN(tripId)) {
      initialPayload.extraData = {}
      initialPayload.extraData.uid = tripId
    } else if (tripId) {
      initialPayload.tripId = tripId
    }

    if (extras) {
      Object.assign(initialPayload, extras)
    }

    return Axios.post(`@coreapi/companies/${companyId}/receipts`, initialPayload).then(
      ({ data: receipt = {} }) => receipt
    )
  }

  const loadReceipt = async (companyId: any, receiptId: any) => {
    return Axios.get(`@coreapi/companies/${companyId}/receipts/${receiptId}`).then(({ data: receipt = {} }) => receipt)
  }

  const updateReceipt = async (companyId: any, receiptId: any, key: string, value: any) => {
    return Axios.put(`@coreapi/companies/${companyId}/receipts/${receiptId}`, {
      [key]: value
    }).then(({ data: receipt = {} }) => receipt)
  }

  const updateReceiptValues = async (companyId: any, receiptId: any, values: any) => {
    return Axios.put(`@coreapi/companies/${companyId}/receipts/${receiptId}`, values).then(
      ({ data: receipt = {} }) => receipt
    )
  }

  const deleteReceipt = async (companyId: any, receiptId: string | number) => {
    return Axios.delete(`@coreapi/companies/${companyId}/receipts/${receiptId}`)
  }

  return {
    createReceipt,
    loadReceipt,
    updateReceipt,
    updateReceiptValues,
    deleteReceipt
  }
}
