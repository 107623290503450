import { computed } from 'vue'
import xstore from '@/store'
import { OVERVIEW_STORE_ACTIONS, OVERVIEW_STORE_GETTERS, OVERVIEW_STATUS_KEYS, OVERVIEW_STATUS_COLORS, STATUS_COLORS, STATUS_BACKGROUND_COLORS } from '@/enums'
import { useContext, useActiveDates } from '@/composables'

export default function useActivityOverview() {
  const { root } = useContext()
  const { activeDates } = useActiveDates()

  const tripsCount = computed(() => {
    return xstore.getters[OVERVIEW_STORE_GETTERS.TRIPS_COUNT] || {}
  })

  const receiptsCount = computed(() => {
    return xstore.getters[OVERVIEW_STORE_GETTERS.RECEIPTS_COUNT] || {}
  })

  const tripsCountByCompany = (companyId: any) => {
    return tripsCount.value?.[companyId] || {}
  }

  const receiptsCountByCompany = (companyId: any) => {
    return receiptsCount.value?.[companyId] || {}
  }

  const tripsCountTotals = computed(() => {
    const statusTotals: any = {}
    Object.values(tripsCount.value).forEach((company: any) => {
      for (const status in company) {
        // @ts-ignore
        if (OVERVIEW_STATUS_KEYS[status] && company[status] > 0) {
          if (!statusTotals[status]) statusTotals[status] = 0
          statusTotals[status] += company[status]
        }
      }
    })
    return statusTotals
  })

  const receiptsCountTotals = computed(() => {
    const statusTotals: any = {}
    Object.values(receiptsCount.value).forEach((company: any) => {
      for (const status in company) {
        // @ts-ignore
        if (OVERVIEW_STATUS_KEYS[status] && company[status] > 0) {
          if (!statusTotals[status]) statusTotals[status] = 0
          statusTotals[status] += company[status]
        }
      }
    })
    return statusTotals
  })

  const loadTripsCount = async () => {
    try {
      const payload = {
        startDate: activeDates.value?.startDate,
        endDate: activeDates.value?.endDate
      }
      await xstore.dispatch(OVERVIEW_STORE_ACTIONS.GET_TRIPS_COUNT, payload)
      return true
    } catch (error) {
      root?.$snackbar('Failed to load report stats', 'error', 5000)
    }
  }

  const loadReceiptsCount = async () => {
    try {
      const payload = {
        startDate: activeDates.value?.startDate,
        endDate: activeDates.value?.endDate
      }
      await xstore.dispatch(OVERVIEW_STORE_ACTIONS.GET_RECEIPTS_COUNT, payload)
      return true
    } catch (error) {
      root?.$snackbar('Failed to load receipt stats', 'error', 5000)
    }
  }

  function setTagColor(status: keyof typeof OVERVIEW_STATUS_KEYS) {
    // @ts-ignore
    const colorKey = OVERVIEW_STATUS_COLORS[OVERVIEW_STATUS_KEYS[status]]
    // @ts-ignore
    return { color: STATUS_COLORS[colorKey], background: STATUS_BACKGROUND_COLORS[colorKey] }
  }

  return {
    tripsCount,
    tripsCountTotals,
    receiptsCount,
    receiptsCountTotals,
    tripsCountByCompany,
    receiptsCountByCompany,
    loadTripsCount,
    loadReceiptsCount,
    setTagColor
  }
}
