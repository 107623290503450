'use strict'

import Receipts from '../receipts'
import { safeAdd } from '../helpers'
import { BILLING_TYPE } from '@/enums'

/**
 * General trip-related functions that try to resolve discrepancies w/ custom trips
 */

const CustomTrips = {
  TUT: 1
}

/**
 * Interface for custom trip handler:
 * supportsEditing: Boolean // weather the handler supports editing
 * forcedReportChoice: String // type of report that is automatically chosen on report choice
 *
 * getReceiptTripId(receipt) // return the working id for the trip
 * setReceiptTripId(receipt, trip, callback) // sets the custom tripId for receipt
 * transformTrip(trip) // transforms the server returned trip
 */
const CustomTripHandlers = {
  [CustomTrips.TUT]: {
    supportsEditing: false,
    forcedReportChoice: 'default',

    getReceiptTripId(receipt) {
      return receipt.extraData && receipt.extraData.uid
    },
    setReceiptCustomTripId(receipt, trip, callback) {
      if (!receipt.extraData) {
        receipt.extraData = {}
      }
      receipt.extraData.uid = trip.uid || trip.id

      Receipts.update.call(
        this,
        receipt.companyId,
        receipt.id,
        'extraData',
        receipt.extraData,
        function (error, result) {
          if (error) {
            return callback(error)
          }

          result.tripId = trip.uid || trip.id
          callback(null, result)
        }
      )
    },
    transformTrip(trip) {
      trip.id = trip.uid || trip.id
      trip._custom = CustomTrips.TUT
      trip._customSupportsEdit = this.supportsEditing
    }
  }
}

/**
 * Gets the trip customization identifier for the given company
 * @param {Object} company
 * @returns {Number}
 */
function _getCustomizationId(company) {
  switch (company?.extraData?.tripHandler) {
    case 'custom/taltech':
      return CustomTrips.TUT
  }
}

/**
 * Returns valid trip id bound to the receipt.
 * @param {*} receipt
 */
function getReceiptTripId(receipt, { company }) {
  // TODO: this should be deprecated at some point
  if (receipt.tripId) {
    return receipt.tripId
  }

  var customizationId = _getCustomizationId(company)
  if (!customizationId) {
    return receipt.tripId
  }

  var handler = CustomTripHandlers[customizationId]
  if (!handler) {
    return receipt.tripId
  }

  return handler.getReceiptTripId(receipt)
}

/**
 * Binds the trip to receipt using custom rule (if present).
 */
function setReceiptCustomTripId(receipt, trip, callback) {
  var handler = CustomTripHandlers[trip._custom]
  if (!handler) {
    return callback()
  }

  handler.setReceiptCustomTripId.call(this, receipt, trip, callback)
}

/**
 * Preprocesses the trips returned from server.
 * @param {*} trips
 */
function preprocessTrips(trips, { company }) {
  var _trips = {}

  var handler
  var customizationId = _getCustomizationId(company)
  if (customizationId) {
    handler = CustomTripHandlers[customizationId]
  }

  for (var i = 0; i < trips.length; i++) {
    var trip = trips[i]

    if (handler) {
      handler.transformTrip(trip)
    }

    _trips[trip.id] = trip
  }
  return _trips
}

/**
 * Transforms the trip returned from server based on custom rule if present
 * @param {Object} trip
 * @param {Object} params
 */
function transformTrip(trip, { company, user }) {
  var customizationId = _getCustomizationId(company)
  if (!customizationId) {
    return
  }

  var handler = CustomTripHandlers[customizationId]

  handler.transformTrip(trip)
}

/**
 *
 * @param {Object} company
 * @returns {String}
 */
function getForcedReportChoice(company) {
  var customizationId = _getCustomizationId(company)
  if (!customizationId) {
    return
  }

  var handler = CustomTripHandlers[customizationId]

  return handler.forcedReportChoice
}

export { getReceiptTripId, preprocessTrips, setReceiptCustomTripId, transformTrip, getForcedReportChoice }
