/* eslint-disable */
// @ts-nocheck
import { xstore } from '@/store'
import { Company, User, Trip } from '@/models'
import { useTrips, useContext, useIntegrations, useExpenseFlow, useLoadingState } from '@/composables'
import { TRIPS_STORE_ACTIONS, INTEGRATION } from '@/enums'

export default function useExport() {
  const { root } = useContext()
  const { loadTrip } = useTrips()
  const { activeIntegration } = useIntegrations()
  const { submitReport } = useExpenseFlow()
  const { setLoadingState } = useLoadingState()

  const canExport = (company: Company | undefined, user: User): Boolean => {
    const hasAllowedIntegration = [INTEGRATION.ASTERI, INTEGRATION.JUMIS_START, INTEGRATION.VISMA_FIVALDI_CSV].includes(
      activeIntegration.value?.id
    )
    const userRole = company.companyRoles ? company.companyRoles.find((role) => role.userId === user.id) : {}
    const isAdmin = userRole?.rolename === 'admin'
    return isAdmin && hasAllowedIntegration
  }

  const canExportAndCopy = (company: Company | undefined, user: User): Boolean => {
    const hasAllowedIntegration = [INTEGRATION.ASTERI, INTEGRATION.VISMA_FIVALDI_CSV].includes(
      activeIntegration.value?.id
    )
    const userRole = company.companyRoles ? company.companyRoles.find((role) => role.userId === user.id) : {}
    const isAdmin = userRole?.rolename === 'admin'
    return isAdmin && hasAllowedIntegration
  }

  const copyCSV = (dataToCopy: any) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const text = event.target.result
      if (text) {
        navigator['clipboard']
          .writeText(text)
          .then(() => {
            root?.$notification(`CSV ${root?.t('copied')}`, 'success', 5000)
          })
          .catch((e: any) => {
            root?.$notification('Failed to copy content', 'error', 5000)
          })
        return
      }
      root?.$notification('Failed to copy content', 'error', 5000)
    }
    reader.readAsText(dataToCopy)
  }

  const downloadFile = (response: any, trip: Trip) => {
    const blob = new Blob([response.data], { type: response.data.type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = trip.alias ? trip.alias.replace('.', '_') : 'report'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  const exportTrip = async (company: Company, trip: Trip, action: string, tripReceipts: any): Promise<void> => {
    try {
      setLoadingState(true)

      await submitReport(company, trip, tripReceipts)

      const response = await xstore.dispatch(TRIPS_STORE_ACTIONS.IMPORT_TRIP, {
        trip,
        companyId: company?.id
      })

      if (action === 'COPY') {
        copyCSV(response.data)
      }

      if (action === 'DOWNLOAD') {
        downloadFile(response, trip)
      }

      await loadTrip(trip?.id)
    } catch (error) {
      throw error
    } finally {
      setLoadingState(false)
    }
  }

  return {
    canExport,
    canExportAndCopy,
    exportTrip
  }
}
