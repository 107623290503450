import {
  createApp,
  VNode
} from 'vue'

import VueLazyLoad from 'vue3-lazyload'

import '@/lib/globalInterceptors/axios.interceptor'
import UiNotification from '@/plugins/notification'
import createRouter from '@cloud/router/cloud.router'
import Cloud from '@cloud/Cloud.vue'
import store from '@/store'

import {
  helpers,
  l10n,
} from '@/plugins'

import {
  registerDynamicComponents,
  registerStaticComponents
} from '@/components'

import {
  routerLocationKey
} from '@/providers'

import icons from '@ui/icons'

import Axios from 'axios'

Axios.defaults.headers.common['x-app-id'] = 'cloud-v2'
Axios.defaults.withCredentials = true

const render = (config: any | undefined) => {
  const app = createApp(Cloud)

  app.use(registerDynamicComponents)
  app.use(registerStaticComponents)
  
  app.use(VueLazyLoad)

  const router = createRouter(config)
  
  app.use(router)
  app.use(store)
  app.use(helpers, config)
  app.use(l10n)
  app.use(icons)

  app.use(UiNotification)

  app.provide(routerLocationKey, router)

  app.mount('#__COSTPOCKET__')
}

try {
  Axios
    .get('/config.json', {})
    .then(({ data: config }) => {
      render(config)
    })
    .catch(() => {
      render(undefined)
    })
} catch (error) {
  render(undefined)
}

