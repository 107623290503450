import { ComputedRef, computed, ref } from 'vue'
import iban from 'iban'
import { xstore } from '@/store'
import {
  RECEIPTS_STORE_ACTIONS,
  EDITABLE_RECEIPT_KEY,
  DOCUMENT_TYPE,
  BILLING_TYPE,
  RECEIPTS_STORE_GETTERS,
  COST_TYPES_STORE_GETTERS,
  FIELD_TYPE,
  COST_TYPE,
  PAID_WITH,
  PAYMENT_TYPE,
  DOCUMENT_SUBTYPE
} from '@/enums'
import { PaymentMethod, Receipt, EditableField } from '@/models'
import {
  useInstanceHelpers,
  useContext,
  useCompanies,
  useReceipts,
  useExpenseFlow,
  useItemRows,
  usePaymentMethods,
  useLoadingState,
  useDuplicates,
  useHelpers
} from '@/composables'
import { compareObjAgainstTarget } from '@/lib/helpers'

export default function useReceiptFields() {
  const loadingFields = ref<EDITABLE_RECEIPT_KEY[]>([])

  const { t } = useInstanceHelpers()
  const { root } = useContext()
  const { activeCompany } = useCompanies()
  const { activeReceipt, loadReceipt } = useReceipts()
  const { digitizeReceipt } = useExpenseFlow()
  const { setLoadingState } = useLoadingState()
  const { deleteAllItemRows, addItemRow } = useItemRows()
  const { paymentMethodsForUser } = usePaymentMethods()
  const { duplicatesByReceiptId } = useDuplicates()
  const { findMostUsedCurrencies, ocrWordList } = useHelpers()

  const isAttachment = computed(() => activeReceipt.value?.docType === DOCUMENT_TYPE.ATTACHMENT)
  const isPaymentCardStatement = computed(() => activeReceipt.value?.docSubType === DOCUMENT_SUBTYPE.CARD_STATEMENT)

  async function updateReceiptField(target: EDITABLE_RECEIPT_KEY, receipt: Receipt, values: any, config: any = {}) {
    if (!receipt?.id) return

    try {
      // using exmpl: config.getParentObj: (payload) => ({paymentDetails: payload})
      if (values && typeof config.getParentObj === 'function') {
        values = config.getParentObj(values)
      }

      if (compareObjAgainstTarget(values, receipt)) {
        return
      }

      if (!config.disableLoading) {
        loadingFields.value.push(target)
      }

      setLoadingState(true)

      await xstore.dispatch(RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_DATA, {
        companyId: activeCompany?.value?.id,
        receiptId: receipt?.id,
        disablePopUp: config.disablePopUp ?? false,
        values
      })

      const receiptDuplicates = duplicatesByReceiptId(Number(receipt?.id))
      for (const duplicate of receiptDuplicates) {
        await loadReceipt(duplicate.id)
      }

      root?.$notification('saved', 'success', 4000)
    } catch (error) {
      root?.$notification(root?.eh(error), 'error', 9000)
    } finally {
      if (!config.disableLoading) {
        loadingFields.value = loadingFields.value.filter((field) => field !== target)
      }
      setLoadingState(false)
    }
  }

  const receiptFields: ComputedRef<EditableField[]> = computed(() => {
    const fields: EditableField[] = [
      {
        name: EDITABLE_RECEIPT_KEY.SUPPLIER,
        type: FIELD_TYPE.TEXT,
        isHighlighted: ({ supplier }: Receipt) => !supplier,
        isVisible: !isAttachment.value && !activeReceipt.value?.extraData?.emptyReceipt,
        label: t('supplier'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.SUPPLIER),
        getValue: ({ supplier }: Receipt) => supplier,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.SUPPLIER], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.SUPPLIER, receipt, { supplier: value })
        },
        supportsSuggestions: true,
        filterSuggestions: true,
        getValueSuggestions: ocrWordList()
      },
      {
        name: EDITABLE_RECEIPT_KEY.SUPPLIER_REG_NR,
        type: FIELD_TYPE.TEXT,
        isHighlighted: ({ supplierRegNumber }: Receipt) => !supplierRegNumber,
        isVisible: !isAttachment.value && !activeReceipt.value?.extraData?.emptyReceipt,
        label: t('supplierRegNumber'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.SUPPLIER_REG_NR),
        getValue: ({ supplierRegNumber }: Receipt) => supplierRegNumber,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.SUPPLIER_REG_NR], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.SUPPLIER_REG_NR, receipt, { supplierRegNumber: value || null })
        },
        supportsSuggestions: true,
        filterSuggestions: true,
        getValueSuggestions: ocrWordList()
      },
      {
        name: EDITABLE_RECEIPT_KEY.SUPPLIER_VAT_NR,
        type: FIELD_TYPE.TEXT,
        isHighlighted: ({ supplierVATNumber }: Receipt) => !supplierVATNumber,
        isVisible: !isAttachment.value && !activeReceipt.value?.extraData?.emptyReceipt,
        label: t('VATNumber'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.SUPPLIER_VAT_NR),
        getValue: ({ supplierVATNumber }: Receipt) => supplierVATNumber,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.SUPPLIER_VAT_NR], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.SUPPLIER_VAT_NR, receipt, { supplierVATNumber: value || null })
        },
        supportsSuggestions: true,
        filterSuggestions: true,
        getValueSuggestions: ocrWordList()
      },
      {
        name: EDITABLE_RECEIPT_KEY.DOCUMENT_ID,
        type: FIELD_TYPE.TEXT,
        isHighlighted: ({ documentId }: Receipt) => !documentId,
        isVisible: !isAttachment.value && !activeReceipt.value?.extraData?.emptyReceipt,
        label: t('documentId'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.DOCUMENT_ID),
        getValue: ({ documentId }: Receipt) => documentId,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.DOCUMENT_ID], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.DOCUMENT_ID, receipt, { documentId: value || null })
        },
        supportsSuggestions: true,
        filterSuggestions: true,
        getValueSuggestions: ocrWordList()
      },
      {
        name: EDITABLE_RECEIPT_KEY.ISSUED,
        type: FIELD_TYPE.DATE,
        isHighlighted: ({ issued }: Receipt) => !issued,
        isVisible: !isAttachment.value,
        label: t('issued'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.ISSUED),
        getValue: ({ issued }: Receipt) => issued,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.ISSUED], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.ISSUED, receipt, { issued: value })
        }
      },
      {
        name: EDITABLE_RECEIPT_KEY.CURRENCY,
        type: FIELD_TYPE.TEXT,
        isHighlighted: ({ currency }: Receipt) => !currency,
        isVisible: !isAttachment.value,
        label: t('currency'),
        maxLength: 3,
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.CURRENCY),
        getValue: ({ currency }: Receipt) => currency,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.CURRENCY], receipt: Receipt) => {
          value = value?.toUpperCase() || null
          updateReceiptField(EDITABLE_RECEIPT_KEY.CURRENCY, receipt, { currency: value })
        },
        supportsSuggestions: true,
        filterSuggestions: false,
        getValueSuggestions: (value?: string) => {
          if (!value) return []
          return findMostUsedCurrencies(value).filter((currency) => currency !== null)
        }
      },
      {
        name: EDITABLE_RECEIPT_KEY.ITEM_ROWS,
        type: FIELD_TYPE.ITEM_ROWS,
        isVisible: !isAttachment.value,
        label: '',
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.ITEM_ROWS),
        getValue: ({ itemRows }: Receipt) => itemRows
      },
      {
        name: EDITABLE_RECEIPT_KEY.COMMENT,
        type: FIELD_TYPE.TEXTAREA,
        isVisible: !isPaymentCardStatement.value,
        label: t('comment'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.COMMENT),
        getValue: ({ comment }: Receipt) => comment,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.COMMENT], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.COMMENT, receipt, { comment: value })
        }
      },
      {
        name: EDITABLE_RECEIPT_KEY.BILLING_TYPE,
        type: FIELD_TYPE.SELECT,
        canUnselect: true,
        isVisible: !isAttachment.value,
        label: t('billingType'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.BILLING_TYPE),
        isMissing: ({ billingType } = {}) => !billingType,
        getValue: ({ billingType }: Receipt) => (billingType ? billingType : `${t('select')} ${t('billingType')}`),
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.BILLING_TYPE], receipt: Receipt) => {
          if (!value) value = null
          updateReceiptField(EDITABLE_RECEIPT_KEY.BILLING_TYPE, receipt, { billingType: value })
        },
        options: [
          { title: t('paidByCompanyFunds'), value: 'company' },
          { title: t('paidPersonally'), value: 'personal' }
        ]
      },
      {
        name: EDITABLE_RECEIPT_KEY.PAID_WITH,
        type: FIELD_TYPE.SELECT,
        isVisible: activeReceipt?.value?.billingType === BILLING_TYPE.COMPANY && !isAttachment.value,
        label: t('paidBy'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.PAID_WITH),
        isMissing: ({ paidWith, paymentMethodId } = {}) => !paymentMethodId && !paidWith,
        getValue: ({ paidWith, paymentMethodId }: Receipt) => paymentMethodId || paidWith,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.PAID_WITH], receipt: Receipt) => {
          const payload: any = {}
          if (value && Object.values(PAID_WITH).includes(value)) {
            payload.paidWith = value
            if (receipt.paymentMethodId) {
              payload.paymentMethodId = null
            }
          } else {
            payload.paymentMethodId = value || null
          }
          updateReceiptField(EDITABLE_RECEIPT_KEY.PAID_WITH, receipt, payload)
        },
        options: (() => {
          let paymentMethodOptions: any = paymentMethodsForUser(activeReceipt.value?.userId).map(
            (method: PaymentMethod) => {
              let methodTitle = t(method.type)
              if (method.type === PAYMENT_TYPE.BANK_ACCOUNT) {
                methodTitle = `${methodTitle} ${method.bankAccountNr || ''}`
              }
              if (method.type === PAYMENT_TYPE.CREDIT_CARD || method.type === PAYMENT_TYPE.DEBIT_CARD) {
                methodTitle = `${methodTitle} ${method.cardLfd || ''}`
              }
              return { title: methodTitle, value: method.id, extraInfo: method.name }
            }
          )
          if (!paymentMethodOptions.length || !activeCompany.value?.settings.usesCustomPaymentMethods) {
            paymentMethodOptions = Object.values(PAID_WITH).map((paidWith) => ({
              title: t(paidWith),
              value: paidWith
            }))
            return paymentMethodOptions
          }
          if (activeReceipt.value?.paidWith && !activeReceipt.value?.paymentMethodId) {
            paymentMethodOptions.unshift({
              title: t(activeReceipt.value.paidWith),
              value: activeReceipt.value.paidWith
            })
          }
          return paymentMethodOptions
        })()
      },
      {
        name: EDITABLE_RECEIPT_KEY.COST_TYPE,
        type: FIELD_TYPE.COST_TYPE,
        isVisible: !isAttachment.value,
        label: t('costType'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.COST_TYPE),
        isMissing: ({ costType } = {}) => !costType,
        getValue: ({ costType, costTypeId }: Receipt) => costTypeId || costType,
        handleOnChange: (value: Receipt[EDITABLE_RECEIPT_KEY.PAID_WITH], receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.PAID_WITH, receipt, {
            ...(!activeCompany?.value?.settings?.usesCustomCostTypes && { costTypeId: null }),
            [activeCompany?.value?.settings?.usesCustomCostTypes ? 'costTypeId' : 'costType']: value
          })
        },
        options: activeCompany?.value?.settings?.usesCustomCostTypes
          ? xstore.getters[COST_TYPES_STORE_GETTERS.EXPENSE_TYPES]
          : Object.values(COST_TYPE) // used with CostTypeSelect
      },
      {
        name: EDITABLE_RECEIPT_KEY.DOC_TYPE,
        type: FIELD_TYPE.SELECT,
        isVisible:
          !activeReceipt.value?.extraData?.emptyReceipt &&
          !isPaymentCardStatement.value &&
          activeCompany.value?.countryCode !== 'PL',
        label: t('docType'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.DOC_TYPE),
        getValue: ({ docType }: Receipt) => docType || DOCUMENT_TYPE.RECEIPT,
        handleOnChange: async (value: Receipt[EDITABLE_RECEIPT_KEY.DOC_TYPE], receipt: Receipt) => {
          if (value === DOCUMENT_TYPE.ATTACHMENT) {
            await deleteAllItemRows(activeReceipt.value, activeCompany.value?.id)
            await digitizeReceipt(activeCompany.value, activeReceipt.value)
          } else if (
            activeReceipt.value?.itemRows &&
            activeReceipt.value?.itemRows?.length < 1 &&
            activeCompany.value
          ) {
            await addItemRow(activeCompany.value, activeReceipt.value)
          }
          updateReceiptField(EDITABLE_RECEIPT_KEY.DOC_TYPE, receipt, {
            docType: value
          })
        },
        options: [
          isAttachment.value && activeReceipt.value?.currency === null
            ? {}
            : { title: t('invoice'), value: DOCUMENT_TYPE.INVOICE },
          isAttachment.value && activeReceipt.value?.currency === null
            ? {}
            : { title: t('receipt'), value: DOCUMENT_TYPE.RECEIPT },
          activeReceipt.value?.tripId ? { title: t('nonCostDocument'), value: DOCUMENT_TYPE.ATTACHMENT } : {}
        ]
      },
      {
        name: EDITABLE_RECEIPT_KEY.IBAN,
        type: FIELD_TYPE.TEXT,
        isHighlighted: (receipt: Receipt) => false,
        isVisible: activeReceipt?.value?.docType === DOCUMENT_TYPE.INVOICE,
        label: t('IBAN'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.IBAN),
        getValue: ({ paymentDetails }: Receipt) => paymentDetails?.accounts?.[0]?.number,
        handleOnChange: (value: any, receipt: Receipt) => {
          if (iban.isValid(value)) {
            updateReceiptField(EDITABLE_RECEIPT_KEY.IBAN, receipt, {
              paymentDetails: {
                accounts: [{ number: value }]
              }
            })
          } else {
            root?.$notification('notValidIban', 'error', 6000)
          }
        }
      },
      {
        name: EDITABLE_RECEIPT_KEY.REF_NUM,
        type: FIELD_TYPE.TEXT,
        isHighlighted: (receipt: Receipt) => false,
        isVisible: activeReceipt?.value?.docType === DOCUMENT_TYPE.INVOICE,
        label: t('refNum'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.REF_NUM),
        getValue: ({ paymentDetails }: Receipt) => paymentDetails?.refNum,
        handleOnChange: (value: any, receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.REF_NUM, receipt, {
            paymentDetails: {
              refNum: value
            }
          })
        }
      },
      {
        name: EDITABLE_RECEIPT_KEY.DUE_DATE,
        type: FIELD_TYPE.DATE,
        isHighlighted: (receipt: Receipt) => false,
        isVisible: activeReceipt?.value?.docType === DOCUMENT_TYPE.INVOICE,
        label: t('dueDate'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.DUE_DATE),
        getValue: ({ paymentDetails }: Receipt) => paymentDetails?.dueDate,
        handleOnChange: (value: any, receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.DUE_DATE, receipt, {
            paymentDetails: {
              dueDate: value
            }
          })
        }
      },
      {
        name: EDITABLE_RECEIPT_KEY.PAID,
        type: FIELD_TYPE.CHECKBOX,
        isHighlighted: (receipt: Receipt) => false,
        isVisible: !isAttachment.value && activeCompany.value?.countryCode === 'LV',
        label: t('paid'),
        isLoading: loadingFields.value.includes(EDITABLE_RECEIPT_KEY.PAID),
        getValue: ({ paymentDetails }: Receipt) => paymentDetails?.paid,
        handleOnChange: (value: any, receipt: Receipt) => {
          updateReceiptField(EDITABLE_RECEIPT_KEY.PAID, receipt, {
            paymentDetails: {
              paid: value
            }
          })
        }
      }
    ]
    return fields
  })

  return {
    receiptFields
  }
}
