<template>
  <div class="version-update-warning-container">
    <p class="paragraph-lg-regular">{{ t('newVersionWarning') }}</p>
    <ui-button-desktop class="m-t-8" :label="t('OK')" @click="reloadPage()"></ui-button-desktop>
  </div>
</template>

<script>
export default {
  name: 'CloudUpdateWarning',
  setup() {
    function reloadPage() {
      window.location = window.location + `?time=${encodeURIComponent(new Date().toISOString())}`
    }

    return {
      reloadPage
    }
  }
}
</script>

<style lang="scss" scoped>
.version-update-warning-container {
  position: fixed;
  bottom: 40px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  background: $medium-dark-grey;
  color: $white;
  z-index: 10000;
}
</style>
