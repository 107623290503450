import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/login/callback',
    component: () => import('@cloud/views/AuthCallback.view.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/overview/:summaryTab',
    component: () => import('@cloud/views/Dashboard.view.vue')
  },
  {
    path: '/:companyId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/create',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/receipt/:receiptId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/receipt/:receiptId/create',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/trip/:tripId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/trip/:tripId/receipt/:receiptId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/trip/:tripId/mileage/:mileageId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/new-trip/:tripType',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/approval/:approvalId/trip/:tripId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/approval/:approvalId/receipt/:receiptId',
    component: () => import('@cloud/views/Main.view.vue')
  },
  {
    path: '/:companyId/:tab/:layout/approval/:approvalId/trip/:tripId/receipt/:receiptId',
    component: () => import('@cloud/views/Main.view.vue')
  }
]

export default routes