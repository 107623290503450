export enum SETTING_STORE_STATE {
  ACTIVE_DATES = 'Setting/ACTIVE_DATES',
  SELECT_RECEIPTS_BY = 'Setting/SELECT_RECEIPTS_BY',
  SELECT_TRIPS_BY = 'Setting/SELECT_TRIPS_BY',
  LOADING_STATE = 'Setting/LOADING_STATE'
}

export enum SETTING_STORE_MUTATIONS {
  SET_ACTIVE_DATES = 'Setting/SET_ACTIVE_DATES',
  SET_SELECT_RECEIPTS_BY = 'Seting/SET_SELECT_RECEIPTS_BY',
  SET_SELECT_TRIPS_BY = 'Seting/SET_SELECT_TRIPS_BY',
  SET_LOADING_STATE = 'Setting/SET_LOADING_STATE'
}

export enum SETTING_STORE_ACTIONS {
  UPDATE_ACTIVE_DATES = 'Setting/UPDATE_ACTIVE_DATES',
  UPDATE_LOADING_STATE = 'Setting/UPDATE_LOADING_STATE',
  UPDATE_SELECT_RECEIPTS_BY = 'Seting/UPDATE_SELECT_RECEIPTS_BY',
  UPDATE_SELECT_TRIPS_BY = 'Seting/UPDATE_SELECT_TRIPS_BY',
}

export enum SETTING_STORE_GETTERS {
  ACTIVE_DATES = 'Setting/ACTIVE_DATES',
  SELECT_RECEIPTS_BY = 'Setting/SELECT_RECEIPTS_BY',
  SELECT_TRIPS_BY = 'Setting/SELECT_TRIPS_BY',
  IS_LOADING = 'Setting/IS_LOADING'
}

export enum SORT_KEYS {
  STATUS = 'status',
  ISSUED = 'issued',
  SUBMITTER = 'submitter',
  RECEIPT_SUBMITTED = 'createdAt',
  REPORT_SUBMITTED = 'submittedAt',
  DOCUMENT_ID = 'documentId',
  SUPPLIER = 'supplier',
  NAME = 'name',
  SUM = 'sum',
  REIMBURSABLE = 'billingType',
  EXTREF = 'extRef',
  CARDLFD = 'cardLfd',
  BANKNAME = 'bankName',
  DATE = 'date',
  CURRENCY = 'currency',
  DOCUMENT_TYPE = 'docType',
  DOCUMENT = 'document',
  EXPENSE_TYPE = 'costType',
  COMMENT = 'comment'
}

export enum DATE_FILTER_SELECTIONS {
  FROM_PAST_MONTH = 'FROM_PAST_MONTH',
  ONGOING_MONTH = 'ONGOING_MONTH',
  ONGOING_YEAR = 'ONGOING_YEAR',
  PAST_MONTH = 'PAST_MONTH',
  YESTERDAY = 'YESTERDAY',
  CUSTOM = 'CUSTOM',
  TODAY = 'TODAY'
}

export enum NAVIGATION_TAB {
  RECEIPTS = 'receipts',
  REPORTS = 'reports',
  TRANSACTIONS = 'transactions',
  APPROVALS = 'approvals'
}

export enum TRIP_DATES_CRITERIA {
  CREATED_AT = 'reportAddedBetween',
  CREATED_FOR = 'reportDatedBetween',
  ALL = 'reportDatedOrAdded'
}

export enum RECEIPT_DATES_CRITERIA {
  ISSUED = 'docIssuedBetween',
  CREATED = 'docAddedBetween'
}