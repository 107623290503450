import { validateStatus } from '@/lib/status'
import { Company, Receipt, ReceiptStatus } from '@/models'
import { RECEIPT_STATUS, RECEIPT_STATUS_CATEGORY, STATUS_COLORS, STATUS_SEQUENCE, DOCUMENT_TYPE } from '@/enums'

export const receiptGeneralStatus = (receipt: Receipt, company: Company): ReceiptStatus | null => {
  if (!receipt?.id || receipt.status == null) return null

  switch (true) {
    case receipt?.docType === DOCUMENT_TYPE.ATTACHMENT: {
      return {
        color: STATUS_COLORS.WHITE,
        status: RECEIPT_STATUS_CATEGORY.ATTACHMENT,
        label: 'nonCostDocument',
        index: STATUS_SEQUENCE.ATTACHMENT
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.DEPARTED, RECEIPT_STATUS.APPROVED, -RECEIPT_STATUS.BOUNCED]): {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'approvedAndSent',
        status: RECEIPT_STATUS_CATEGORY.APPROVED_AND_SENT,
        index: STATUS_SEQUENCE.APPROVED_AND_SENT
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.DEPARTED, -RECEIPT_STATUS.BOUNCED]): {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'forwardingSuccess',
        status: RECEIPT_STATUS_CATEGORY.SENT,
        index: STATUS_SEQUENCE.SENT
      }
    }

    case receipt.extraData?.emptyReceipt: {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'digitized',
        status: RECEIPT_STATUS_CATEGORY.DIGITIZED,
        index: STATUS_SEQUENCE.DIGITIZED
      }
    }

    case receipt.notApplicable: {
      return {
        color: STATUS_COLORS.RED,
        label: 'notValid',
        status: RECEIPT_STATUS_CATEGORY.INVALID,
        index: STATUS_SEQUENCE.INVALID
      }
    }

    case receipt.noPicture || !validateStatus(receipt.status, [RECEIPT_STATUS.FILE_RECEIVED]): {
      return {
        color: STATUS_COLORS.RED,
        label: 'noPicture',
        status: RECEIPT_STATUS_CATEGORY.PICTURELESS,
        index: STATUS_SEQUENCE.PICTURELESS
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.BOUNCED]): {
      return {
        color: STATUS_COLORS.RED,
        label: 'forwardingFailed',
        status: RECEIPT_STATUS_CATEGORY.BOUNCED,
        index: STATUS_SEQUENCE.BOUNCED
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.REJECTED]): {
      return {
        color: STATUS_COLORS.RED,
        label: 'rejected',
        status: RECEIPT_STATUS_CATEGORY.REJECTED,
        index: STATUS_SEQUENCE.REJECTED
      }
    }

    case validateStatus(receipt.status, [-RECEIPT_STATUS.SUBMITTED]): {
      return {
        color: STATUS_COLORS.GREY,
        label: 'draft',
        status: RECEIPT_STATUS_CATEGORY.UNSUBMITTED,
        index: STATUS_SEQUENCE.DRAFT
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.APPROVING]): {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'approving',
        status: RECEIPT_STATUS_CATEGORY.APPROVING,
        index: STATUS_SEQUENCE.APPROVING
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.PROCESSING]): {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'tripProcessing',
        status: RECEIPT_STATUS_CATEGORY.PROCESSING,
        index: STATUS_SEQUENCE.PROCESSING
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.APPROVED]): {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'approved',
        status: RECEIPT_STATUS_CATEGORY.APPROVED,
        index: STATUS_SEQUENCE.APPROVED
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.SUBMITTED, RECEIPT_STATUS.DIGITIZED]) &&
      company?.settings?.requireExpenseConfirmation &&
      !company?.settings?.disableSendingSingleDocuments && 
      !receipt.tripId: {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'pendingConfirmation',
        status: RECEIPT_STATUS_CATEGORY.CONFIRMING,
        index: STATUS_SEQUENCE.CONFIRMING
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.DIGITIZED, -RECEIPT_STATUS.DEPARTED]): {
      return {
        color: receipt.tripId ? STATUS_COLORS.GREEN : STATUS_COLORS.YELLOW,
        label: 'digitized',
        status: RECEIPT_STATUS_CATEGORY.DIGITIZED,
        index: STATUS_SEQUENCE.DIGITIZED
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.OCRDONE, -RECEIPT_STATUS.DEPARTED]) && !company?.digitization: {
      return {
        color: receipt.tripId ? STATUS_COLORS.GREEN : STATUS_COLORS.YELLOW,
        label: 'digitized',
        status: RECEIPT_STATUS_CATEGORY.DIGITIZED,
        index: STATUS_SEQUENCE.DIGITIZED
      }
    }

    case validateStatus(receipt.status, [RECEIPT_STATUS.OCRDONE, -RECEIPT_STATUS.DEPARTED]) && company?.digitization: {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'digitizing',
        status: RECEIPT_STATUS_CATEGORY.DIGITIZING,
        index: STATUS_SEQUENCE.DIGITIZING
      }
    }

    default: {
      return null
    }
  }
}

export const receiptCurrencyConversionStatus = (receipt: Receipt): ReceiptStatus | null => {
  if (!receipt || !receipt.id || !receipt.status) return null
  if (validateStatus(receipt.status, [RECEIPT_STATUS.CURRENCY_PROCESSING])) {
    return {
      color: STATUS_COLORS.BLUE,
      label: 'processingCurrency',
      status: RECEIPT_STATUS_CATEGORY.CURRENCY_PROCESSING
    }
  }
  if (validateStatus(receipt.status, [RECEIPT_STATUS.CURRENCY_CONVERTED])) {
    return {
      color: STATUS_COLORS.GREEN,
      label: 'currencyConverted',
      status: RECEIPT_STATUS_CATEGORY.CURRENCY_CONVERTED
    }
  }
  return null
}

export const receiptDuplicateStatus = (receipt: Receipt): ReceiptStatus | null => {
  if (!receipt || !receipt.id || !receipt.status) return null
  if (validateStatus(receipt.status, [RECEIPT_STATUS.NOT_DISTINCT])) {
    return {
      color: STATUS_COLORS.RED,
      label: 'notDistinct',
      status: RECEIPT_STATUS_CATEGORY.DUPLICATE,
      index: STATUS_SEQUENCE.DUPLICATE
    }
  }
  return null
}

export const receiptStatuses = (receipt: Receipt, company: Company | undefined): (ReceiptStatus | null)[] => {
  if (!receipt || !company) return []
  const statuses = [
    receiptGeneralStatus(receipt, company),
    receiptDuplicateStatus(receipt),
    receiptCurrencyConversionStatus(receipt)
  ]
  return statuses.filter((status) => status !== null)
}
