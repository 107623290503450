import moment from 'moment'
import { xstore } from '@/store'
import { cloneDeep } from 'lodash-es'
import { COUNTRY_CODE, DailyAllowanceItem, DailyAllowanceTotals, TripDestination } from '@/models'
import useFormatters from './useFormatters'
import { TRIPS_STORE_ACTIONS } from '@/enums'

export default function useDailyAllowance() {
  const { formatDate } = useFormatters()

  const minStartDate = (index: number, tripDestinations: Array<any>) => {
    return tripDestinations[index - 1] ? tripDestinations[index - 1].endDate || '' : ''
  }

  const minEndDate = (index: number, tripDestinations: Array<any>) => {
    return tripDestinations[index].startDate || ''
  }

  const mapDailyAllowanceData = (dailyAllowanceData: Array<DailyAllowanceItem>, destinationOptions: Array<any>) => {
    const entries = cloneDeep(dailyAllowanceData).sort((a: DailyAllowanceItem, b: DailyAllowanceItem) => {
      return +new Date(a.start) - +new Date(b.start)
    })
    let destinations: Array<TripDestination> = []
    let extraInfo: any = {}
    entries.forEach((entry: DailyAllowanceItem, index: number) => {
      const oneDestination: TripDestination = {
        id: entry.id,
        index,
        sum: entry.sum,
        currency: entry.currency || '',
        days: entry.days,
        destination: entry.destination,
        searchInput: destinationOptions.find((option) => option.value === entry.destination)?.title,
        startDate: formatDate(entry.start?.slice(0, 10)),
        endDate: formatDate(entry.end?.slice(0, 10)),
        startTime: entry.info?.startTime,
        endTime: entry.info?.endTime,
        isDestinationValid: true,
        isStartDateValid: true,
        isEndDateValid: true,
        isStartTimeValid: true,
        isEndTimeValid: true
      }

      if (entry.info?.maxAllowance) {
        extraInfo.maxAllowance = entry.info.maxAllowance
      }

      // EE
      if (entry.info?.departBefore03) {
        extraInfo.departBefore03 = true
      }
      if (entry.info?.departAfter22) {
        extraInfo.departAfter22 = true
      }

      // LV
      if (entry.info?.mealAndAccomodation) {
        extraInfo.mealAndAccomodation = true
      }
      if (entry.info?.returnOvernight) {
        extraInfo.returnOvernight = true
      }
      if (entry.info?.excludeWeekends) {
        extraInfo.calculateWeekends = false
      } else {
        extraInfo.calculateWeekends = true
      }

      // FI
      if (entry.info?.freeMeal) {
        extraInfo.freeMeal = true
      }

      // SE // PL
      if (entry.info?.breakfastIncluded) {
        oneDestination.breakfastIncluded = entry.info?.breakfastIncluded
        extraInfo.breakfastIncluded = true
      }
      if (entry.info?.lunchIncluded) {
        oneDestination.lunchIncluded = entry.info?.lunchIncluded
        extraInfo.lunchIncluded = true
      }
      if (entry.info?.dinnerIncluded) {
        oneDestination.dinnerIncluded = entry.info?.dinnerIncluded
        extraInfo.dinnerIncluded = true
      }

      // LT
      if (entry.info?.nextTripOnLastDay) {
        extraInfo.nextTripOnLastDay = true
      }

      destinations.push(oneDestination)
    })

    return { destinations, extraInfo }
  }

  const calculateTripDuration = (allDestinations: Array<any> = [], hasTimeFields: Boolean) => {
    let duration = {
      d: 0,
      h: 0,
      m: 0
    }
    let tripTotalTime = 0
    if (hasTimeFields) {
      allDestinations.forEach((dest) => {
        if (dest.startDate && dest.endDate && dest.startTime && dest.endTime) {
          let start = moment(dest.startDate + ' ' + dest.startTime, 'YYYY-MM-DDLT')
          let end = moment(dest.endDate + ' ' + dest.endTime, 'YYYY-MM-DDLT')
          tripTotalTime += end.diff(start, 'minutes')
        }
      })

      duration.d = Math.floor(tripTotalTime / 1440)
      let remainingTime = tripTotalTime - duration.d * 1440
      duration.h = Math.floor(remainingTime / 60)
      duration.m = remainingTime - duration.h * 60
    } else {
      allDestinations.forEach((destination) => {
        duration.d += destination.days
      })
    }
    return duration
  }

  const calculateDailyAllowanceTotals = (tripAllowanceEntries: Array<DailyAllowanceItem>) => {
    const allowance: DailyAllowanceTotals = {}
    if (tripAllowanceEntries?.length) {
      tripAllowanceEntries.forEach((entry) => {
        if (allowance[entry.currency] && entry.sum) {
          allowance[entry.currency] = Number((allowance[entry.currency] + Number(entry.sum)).toFixed(2))
        } else if (entry.sum) {
          allowance[entry.currency] = Number(entry.sum)
        }
      })
    }
    return allowance
  }

  const validateDateInput = (dateField: string, dateIndex: number, tripDestinations: Array<any>) => {
    let updatedDestinations = updateValidity(dateField, dateIndex, true, tripDestinations)

    if (
      tripDestinations[dateIndex].startDate &&
      tripDestinations[dateIndex].endDate &&
      new Date(tripDestinations[dateIndex].startDate) > new Date(tripDestinations[dateIndex].endDate)
    ) {
      
      tripDestinations[dateIndex][dateField] = ''
      updatedDestinations = updateValidity(dateField, dateIndex, false, tripDestinations)
    }

    tripDestinations.forEach((entry, index) => {
      if (tripDestinations[index - 1] && tripDestinations[index - 1].endDate && entry.startDate) {
        let prevDestEnd = new Date(tripDestinations[index - 1].endDate)
        let nextDestStart = new Date(entry.startDate)

        if (prevDestEnd > nextDestStart) {
          updatedDestinations[index].startDate = ''
          updatedDestinations[index].endDate = ''
          updatedDestinations[index].isStartDateValid = false
        }
      }
    })

    if (dateField === 'startDate') {
      return validateTimeInput('startTime', dateIndex, tripDestinations)
    } else {
      return validateTimeInput('endTime', dateIndex, tripDestinations)
    }
  }

  const validateTimeInput = (timeField: string, timeIndex: number, tripDestinations: Array<any>) => {
    let updatedDestinations = updateValidity(timeField, timeIndex, true, tripDestinations)

    let prevDate =
      timeField === 'startTime'
        ? tripDestinations[timeIndex - 1] && tripDestinations[timeIndex - 1].endDate
        : tripDestinations[timeIndex].startDate
    let prevTime =
      timeField === 'startTime'
        ? tripDestinations[timeIndex - 1] && tripDestinations[timeIndex - 1].endTime
        : tripDestinations[timeIndex].startTime
    let currentDate =
      timeField === 'startTime' ? tripDestinations[timeIndex].startDate : tripDestinations[timeIndex].endDate

    if (updatedDestinations[timeIndex][timeField] || !prevDate || !prevTime || !currentDate) {
      return updatedDestinations
    }

    let prevFullDate = convertToDate(prevDate, prevTime)
    let currentFullDate = convertToDate(currentDate, tripDestinations[timeIndex][timeField])

    if (!currentFullDate.isSameOrAfter(prevFullDate)) {
      updatedDestinations[timeIndex][timeField] = ''
      updatedDestinations = updateValidity(timeField, timeIndex, false, tripDestinations)
    }

    return updatedDestinations
  }

  const updateValidity = (input: string, index: number, isValid: Boolean, tripDestinations: Array<any>) => {
    let updatedDestinations = [...tripDestinations]
    switch (input) {
      case 'startDate':
        updatedDestinations[index].isStartDateValid = isValid
      case 'endDate':
        updatedDestinations[index].isEndDateValid = isValid
      case 'startTime':
        updatedDestinations[index].isStartTimeValid = isValid
      case 'endTime':
        updatedDestinations[index].isEndTimeValid = isValid
      case 'destination':
        updatedDestinations[index].isDestinationValid = isValid
      default:
        break
    }
    return updatedDestinations
  }

  const validateReport = (tripDestinations: Array<any>, hasTimeFields: Boolean) => {
    const updatedDestinations = cloneDeep(tripDestinations)
    updatedDestinations.forEach((item: any) => {
      if (!item.destination) {
        item.isDestinationValid = false
      }
      if (!item.startDate) {
        item.isStartDateValid = false
      }
      if (!item.endDate) {
        item.isEndDateValid = false
      }
      if (hasTimeFields && !item.startTime) {
        item.isStartTimeValid = false
      }
      if (hasTimeFields && !item.endTime) {
        item.isEndTimeValid = false
      }
    })
    return updatedDestinations
  }

  const isReportValid = (tripDestinations: Array<any>) => {
    return tripDestinations.every((item) => {
      return (
        item.isDestinationValid &&
        item.isStartDateValid &&
        item.isEndDateValid &&
        item.isStartTimeValid &&
        item.isEndTimeValid
      )
    })
  }

  const convertToDate = (date: string, time: string) => {
    return moment(date + ' ' + time, 'YYYY-MM-DDLT')
  }

  const setDailyAllowancePayload = (
    tripDestinations: Array<TripDestination>,
    extraInfo: any,
    includesDailyAllowance: Boolean,
    companyCountryCode: COUNTRY_CODE
  ) => {
    let dailyAllowanceItems: Array<DailyAllowanceItem> = []
    tripDestinations.forEach((item) => {
      const dailyAllowanceItem: DailyAllowanceItem = {
        destination: item.destination,
        start: item.startDate,
        end: item.endDate,
        days: Math.ceil(item.days),
        sum: includesDailyAllowance ? item.sum : 0,
        id: item.id,
        currency: item.currency,
        info: {}
      }

      if (['SE', 'FI', 'PL'].includes(companyCountryCode)) {
        dailyAllowanceItem.info.startTime = item.startTime
        dailyAllowanceItem.info.endTime = item.endTime
        dailyAllowanceItem.start = `${item.startDate} ${item.startTime}`
        dailyAllowanceItem.end = `${item.endDate} ${item.endTime}`
      }

      if (companyCountryCode === 'SE') {
        dailyAllowanceItem.info.breakfastIncluded = extraInfo.breakfastIncluded
        dailyAllowanceItem.info.lunchIncluded = extraInfo.lunchIncluded
        dailyAllowanceItem.info.dinnerIncluded = extraInfo.dinnerIncluded
      }

      if (companyCountryCode === 'PL') {
        dailyAllowanceItem.info.breakfastIncluded = item.breakfastIncluded
        dailyAllowanceItem.info.lunchIncluded = item.lunchIncluded
        dailyAllowanceItem.info.dinnerIncluded = item.dinnerIncluded
      }

      if (companyCountryCode === 'LT') {
        dailyAllowanceItem.info.nextTripOnLastDay = extraInfo.nextTripOnLastDay
      }

      if (companyCountryCode === 'EE') {
        dailyAllowanceItem.info.departBefore03 = extraInfo.departBefore03
        dailyAllowanceItem.info.departAfter22 = extraInfo.departAfter22
      }

      if (companyCountryCode === 'FI') {
        dailyAllowanceItem.info.freeMeal = extraInfo.freeMeal
      }

      if (companyCountryCode === 'LV') {
        dailyAllowanceItem.info.mealAndAccomodation = extraInfo.mealAndAccomodation
        dailyAllowanceItem.info.returnOvernight = extraInfo.returnOvernight
        dailyAllowanceItem.info.excludeWeekends = !extraInfo.calculateWeekends
      }

      if (extraInfo.maxAllowance !== '' && !isNaN(extraInfo.maxAllowance)) {
        dailyAllowanceItem.info.maxAllowance = extraInfo.maxAllowance
      }

      dailyAllowanceItems.push(dailyAllowanceItem)
    })
    return dailyAllowanceItems
  }

  const postNewEntry = (companyId: string | number, tripId: string | number, newAllowanceEntry: DailyAllowanceItem) => {
    delete newAllowanceEntry.id
    return xstore.dispatch(TRIPS_STORE_ACTIONS.SAVE_ALLOWANCE, {
      companyId,
      tripId,
      newAllowanceEntry
    })
  }

  const putUpdatedEntry = (companyId: string | number, tripId: string | number, allowanceEntry: DailyAllowanceItem) => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.UPDATE_ALLOWANCE, {
      companyId,
      tripId,
      allowanceEntry
    })
  }

  const deleteEntry = (companyId: string | number, tripId: string | number, allowanceEntryId: string | number) => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.DELETE_ALLOWANCE, {
      companyId,
      tripId,
      allowanceEntryId
    })
  }

  const saveDailyAllowance = (
    companyId: string | number,
    tripId: string | number,
    allowanceEntries: Array<DailyAllowanceItem>
  ) => {
    let entriesToSave: Array<Promise<void>> = []
    allowanceEntries.forEach((entry) => {
      entriesToSave.push(postNewEntry(companyId, tripId, entry))
    })
    return Promise.all(entriesToSave)
  }

  const updateDailyAllowance = (
    companyId: string | number,
    tripId: string | number,
    allowanceEntries: Array<DailyAllowanceItem>,
    initialDailyAllowanceEntries: Array<DailyAllowanceItem>
  ) => {
    let entriesToUpdate: Array<Promise<void>> = []

    allowanceEntries.forEach((entry) => {
      if (!entry.id) {
        entriesToUpdate.push(postNewEntry(companyId, tripId, entry))
      }
      const entryDidExist: DailyAllowanceItem | undefined = initialDailyAllowanceEntries.find((e) => e.id === entry.id)
      if (entry.id && entryDidExist) {
        entriesToUpdate.push(putUpdatedEntry(companyId, tripId, entry))
      }
    })

    let removableEntries: Array<DailyAllowanceItem> = initialDailyAllowanceEntries.filter(
      (e) => !allowanceEntries.find((entry) => entry.id === e.id)
    )
    removableEntries.forEach((entry) => {
      if (entry.id) {
        entriesToUpdate.push(deleteEntry(companyId, tripId, entry.id))
      }
    })
    return Promise.all(entriesToUpdate)
  }

  return {
    minStartDate,
    minEndDate,
    calculateTripDuration,
    validateDateInput,
    validateTimeInput,
    validateReport,
    isReportValid,
    calculateDailyAllowanceTotals,
    mapDailyAllowanceData,
    setDailyAllowancePayload,
    saveDailyAllowance,
    updateDailyAllowance
  }
}
