import { computed } from 'vue'
import { ITEM_ROW_COLUMN_KEY, FIELD_TYPE, ITEM_ROW_KEY, ACCOUNTING_OBJECT_TYPE, REFERENCE_OBJECT_TYPE } from '@/enums'
import { ItemRow, Receipt, AccountingObject, Company, ItemRowColumn } from '@/models'
import transformSumInput from '@/lib/helpers/transformSumInput'
import {
  useItemRows,
  useContext,
  useAccountingRefs,
  useAccountingSettings,
  useAccountingFields
} from '@/composables'

export default function useItemRowFields() {
  const { root } = useContext()
  const { updateItemRow } = useItemRows()
  const { activeAccountingFields, accountingEntriesEnabled } = useAccountingSettings()
  const { loadTripAccountingEntries, receiptAccountingRefs, updateAccountingRef } = useAccountingRefs()
  const { accountingFieldOptions } = useAccountingFields()

  const defaultColumns: ItemRowColumn[] = [
    ITEM_ROW_COLUMN_KEY.ITEM_CODE,
    ITEM_ROW_COLUMN_KEY.DESCRIPTION,
    ITEM_ROW_COLUMN_KEY.UNIT,
    ITEM_ROW_COLUMN_KEY.QUANTITY,
    ITEM_ROW_COLUMN_KEY.PRICE,
    ITEM_ROW_COLUMN_KEY.VAT_RATE,
    ITEM_ROW_COLUMN_KEY.VAT,
    ITEM_ROW_COLUMN_KEY.VAT_CODE,
    ITEM_ROW_COLUMN_KEY.SUM,
    ITEM_ROW_COLUMN_KEY.TOTAL,
    ITEM_ROW_COLUMN_KEY.ACCOUNT,
    ITEM_ROW_COLUMN_KEY.PROJECT,
    ITEM_ROW_COLUMN_KEY.DIMENSION,
    ITEM_ROW_COLUMN_KEY.COST_OBJECTIVE,
    ITEM_ROW_COLUMN_KEY.DEPARTMENT,
    ITEM_ROW_COLUMN_KEY.CODE
  ]
  const VATcolumns: ItemRowColumn[] = [ITEM_ROW_COLUMN_KEY.SUM, ITEM_ROW_COLUMN_KEY.VAT, ITEM_ROW_COLUMN_KEY.VAT_RATE]

  const accountingColumns: ItemRowColumn[] = [
    ITEM_ROW_COLUMN_KEY.ACCOUNT,
    ITEM_ROW_COLUMN_KEY.COST_OBJECTIVE,
    ITEM_ROW_COLUMN_KEY.DEPARTMENT,
    ITEM_ROW_COLUMN_KEY.DIMENSION,
    ITEM_ROW_COLUMN_KEY.ITEM_CODE,
    ITEM_ROW_COLUMN_KEY.PROJECT,
    ITEM_ROW_COLUMN_KEY.VAT_CODE
  ]

  const companyId = computed(() => root?.$route.params.companyId)
  const receiptId = computed(() => Number(root?.$route.params.receiptId))
  const tripId = computed(() => Number(root?.$route.params.tripId))
  const activeRefs = computed(() => receiptAccountingRefs(receiptId.value || '') || {})

  const itemRowAccountingFields = computed(() => {
    return activeAccountingFields.value?.find(({ target }) => target === REFERENCE_OBJECT_TYPE.ITEM_ROW)?.types || []
  })

  const isSameAsExisting = (key: ITEM_ROW_KEY, value: any, rowNum: number, receipt: Receipt) => {
    if (!rowNum || !receipt.itemRows) return false
    const existingRow = receipt.itemRows.find((row: ItemRow) => row.rowNum === rowNum)
    if (!existingRow) return false
    return existingRow[key] == value
  }

  const updateValue = async (
    rowKey: ITEM_ROW_KEY,
    updatedValue: any,
    rowNum: ItemRow[ITEM_ROW_KEY.ROW_NUM],
    receipt: Receipt
  ) => {
    try {
      if (updatedValue === undefined || isSameAsExisting(rowKey, updatedValue, rowNum, receipt)) return
      return await updateItemRow(companyId.value, receipt, rowKey, updatedValue, rowNum)
    } catch (error) {
      root?.$notification(root.eh(error), 'error', 9000)
    }
  }

  const columnConfig = {
    [ITEM_ROW_COLUMN_KEY.DESCRIPTION]: {
      title: ITEM_ROW_COLUMN_KEY.DESCRIPTION,
      cellWidth: 'minmax(220px, 3fr)'
    },
    [ITEM_ROW_COLUMN_KEY.CODE]: {
      title: ITEM_ROW_COLUMN_KEY.CODE,
      cellWidth: 'minmax(100px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.UNIT]: {
      title: ITEM_ROW_COLUMN_KEY.UNIT,
      cellWidth: 'minmax(60px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.QUANTITY]: {
      title: ITEM_ROW_COLUMN_KEY.QUANTITY,
      cellWidth: 'minmax(60px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.PRICE]: {
      title: ITEM_ROW_COLUMN_KEY.PRICE,
      cellWidth: 'minmax(100px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.VAT_RATE]: {
      title: ITEM_ROW_COLUMN_KEY.VAT_RATE,
      cellWidth: 'minmax(60px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.VAT]: {
      title: ITEM_ROW_COLUMN_KEY.VAT,
      cellWidth: 'minmax(100px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.SUM]: {
      title: ITEM_ROW_COLUMN_KEY.SUM,
      cellWidth: 'minmax(100px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.TOTAL]: {
      title: ITEM_ROW_COLUMN_KEY.TOTAL,
      cellWidth: 'minmax(100px, 1fr)'
    },
    [ITEM_ROW_COLUMN_KEY.ITEM_CODE]: {
      title: ITEM_ROW_COLUMN_KEY.ITEM_CODE,
      cellWidth: 'minmax(160px, 2fr)'
    },
    [ITEM_ROW_COLUMN_KEY.COST_OBJECTIVE]: {
      title: ITEM_ROW_COLUMN_KEY.COST_OBJECTIVE,
      cellWidth: 'minmax(160px, 2fr)'
    },
    [ITEM_ROW_COLUMN_KEY.PROJECT]: {
      title: ITEM_ROW_COLUMN_KEY.PROJECT,
      cellWidth: 'minmax(160px, 2fr)'
    },
    [ITEM_ROW_COLUMN_KEY.ACCOUNT]: {
      title: ITEM_ROW_COLUMN_KEY.ACCOUNT,
      cellWidth: 'minmax(160px, 2fr)'
    },
    [ITEM_ROW_COLUMN_KEY.DIMENSION]: {
      title: ITEM_ROW_COLUMN_KEY.DIMENSION,
      cellWidth: 'minmax(160px, 2fr)'
    },
    [ITEM_ROW_COLUMN_KEY.DEPARTMENT]: {
      title: ITEM_ROW_COLUMN_KEY.DEPARTMENT,
      cellWidth: 'minmax(160px, 2fr)'
    },
    [ITEM_ROW_COLUMN_KEY.VAT_CODE]: {
      title: ITEM_ROW_COLUMN_KEY.VAT_CODE,
      cellWidth: 'minmax(160px, 2fr)'
    }
  }

  const fieldConfig = {
    [ITEM_ROW_COLUMN_KEY.ITEM_CODE]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ITEM_CODE],
      label: ACCOUNTING_OBJECT_TYPE.ITEM_CODE,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ITEM_CODE].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.ITEM_CODE, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
      }
    },
    [ITEM_ROW_COLUMN_KEY.DESCRIPTION]: {
      label: ITEM_ROW_COLUMN_KEY.DESCRIPTION,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ description }: ItemRow) => description,
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.DESCRIPTION], itemRow: ItemRow, receipt: Receipt) => {
        return updateValue(ITEM_ROW_KEY.DESCRIPTION, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.UNIT]: {
      label: ITEM_ROW_COLUMN_KEY.UNIT,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ unit }: ItemRow) => unit,
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.UNIT], itemRow: ItemRow, receipt: Receipt) => {
        return updateValue(ITEM_ROW_KEY.UNIT, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.QUANTITY]: {
      label: ITEM_ROW_COLUMN_KEY.QUANTITY,
      inputType: FIELD_TYPE.NUMBER,
      minValue: 0,
      getValue: ({ quantity }: ItemRow) => Number(quantity),
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.QUANTITY], itemRow: ItemRow, receipt: Receipt) => {
        value = Number(value)
        if (value < 0) {
          value = value * -1
        }
        return updateValue(ITEM_ROW_KEY.QUANTITY, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.PRICE]: {
      label: ITEM_ROW_COLUMN_KEY.PRICE,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ price }: ItemRow) => Number(price).toFixed(2),
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.PRICE], itemRow: ItemRow, receipt: Receipt) => {
        value = Number(transformSumInput(value?.toString()))
        return updateValue(ITEM_ROW_KEY.PRICE, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.VAT_RATE]: {
      label: ITEM_ROW_COLUMN_KEY.VAT_RATE,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ VATrate }: ItemRow) => Number(VATrate),
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.VAT_RATE], itemRow: ItemRow, receipt: Receipt) => {
        value = Number(transformSumInput(value?.toString()))
        if (value > 100) {
          value = 100
        }
        return updateValue(ITEM_ROW_KEY.VAT_RATE, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.VAT]: {
      label: ITEM_ROW_COLUMN_KEY.VAT,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ VATsum }: ItemRow) => Number(VATsum).toFixed(2),
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.VAT], itemRow: ItemRow, receipt: Receipt) => {
        value = Number(transformSumInput(value?.toString()))
        return updateValue(ITEM_ROW_KEY.VAT, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.VAT_CODE]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.VAT_CODE],
      label: ACCOUNTING_OBJECT_TYPE.VAT_CODE,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.VAT_CODE].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.VAT_CODE, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
      }
    },
    [ITEM_ROW_COLUMN_KEY.SUM]: {
      label: ITEM_ROW_COLUMN_KEY.SUM,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ sum }: ItemRow) => Number(sum).toFixed(2),
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.SUM], itemRow: ItemRow, receipt: Receipt) => {
        value = Number(transformSumInput(value?.toString()))
        return updateValue(ITEM_ROW_KEY.SUM, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.TOTAL]: {
      label: ITEM_ROW_COLUMN_KEY.TOTAL,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ total }: ItemRow) => Number(total).toFixed(2),
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.TOTAL], itemRow: ItemRow, receipt: Receipt) => {
        value = Number(transformSumInput(value?.toString()))
        return updateValue(ITEM_ROW_KEY.TOTAL, value, itemRow.rowNum, receipt)
      }
    },
    [ITEM_ROW_COLUMN_KEY.ACCOUNT]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ACCOUNT],
      label: ACCOUNTING_OBJECT_TYPE.ACCOUNT,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ACCOUNT].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: async (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        const updated = await updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.ACCOUNT, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
        if (tripId.value) {
          await loadTripAccountingEntries(tripId.value)
        }
        return updated
      }
    },
    [ITEM_ROW_COLUMN_KEY.PROJECT]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.PROJECT],
      label: ACCOUNTING_OBJECT_TYPE.PROJECT,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.PROJECT].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.PROJECT, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
      }
    },
    [ITEM_ROW_COLUMN_KEY.DIMENSION]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DIMENSION],
      label: ACCOUNTING_OBJECT_TYPE.DIMENSION,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DIMENSION].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.DIMENSION, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
      }
    },
    [ITEM_ROW_COLUMN_KEY.COST_OBJECTIVE]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE],
      label: ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
      }
    },
    [ITEM_ROW_COLUMN_KEY.DEPARTMENT]: {
      ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DEPARTMENT],
      label: ACCOUNTING_OBJECT_TYPE.DEPARTMENT,
      inputType: FIELD_TYPE.SELECT,
      getValue: ({ id }: ItemRow) => {
        return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DEPARTMENT].getValue(
          activeRefs.value,
          REFERENCE_OBJECT_TYPE.ITEM_ROW,
          id
        )
      },
      handleOnChange: (value: AccountingObject['id'], itemRow: ItemRow, _: Receipt) => {
        return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.DEPARTMENT, 'receiptId', receiptId.value, {
          refType: REFERENCE_OBJECT_TYPE.ITEM_ROW,
          refId: itemRow.id
        })
      }
    },
    [ITEM_ROW_COLUMN_KEY.CODE]: {
      label: ITEM_ROW_COLUMN_KEY.CODE,
      inputType: FIELD_TYPE.TEXT,
      getValue: ({ code }: ItemRow) => code,
      handleOnChange: (value: ItemRow[ITEM_ROW_KEY.CODE], itemRow: ItemRow, receipt: Receipt) => {
        return updateValue(ITEM_ROW_KEY.CODE, value, itemRow.rowNum, receipt)
      }
    }
  }

  const filterAvailableItemColumns = (company: Company, columns: ItemRowColumn[]) => {
    const isVATAccountable = company.VATAccountable !== false
    return columns.filter((column) => {
      const isDefault = !VATcolumns.includes(column) && !accountingColumns.includes(column)
      const VATColumnSupported = isVATAccountable && VATcolumns.includes(column)
      const accountingColumnSupported =
        accountingEntriesEnabled.value &&
        accountingColumns.includes(column) &&
        // @ts-ignore // TODO:
        itemRowAccountingFields.value.includes(column)
      return isDefault || VATColumnSupported || accountingColumnSupported
    })
  }

  const mapItemColumnConfig = (company: Company) => {
    let columns: ItemRowColumn[] =
      company.settings?.cloudViewCustomization?.itemColumnOrder || filterAvailableItemColumns(company, defaultColumns)
    columns = columns.filter(column => column.length > 0)
    return columns.map((column) => columnConfig[column])
  }

  return {
    defaultColumns,
    columnConfig,
    fieldConfig,
    filterAvailableItemColumns,
    mapItemColumnConfig
  }
}
