import { computed } from 'vue'
import xstore from '@/store'
import { SETTING_STORE_GETTERS, SETTING_STORE_ACTIONS } from '@/enums'

export default function useLoadingState() {
  const isDataLoading = computed(() => {
    return xstore.getters[SETTING_STORE_GETTERS.IS_LOADING]
  })

  const setLoadingState = (isLoading: Boolean) => {
    return xstore.dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, isLoading)
  }

  return {
    isDataLoading,
    setLoadingState
  }
}
