export enum MILEAGE_STORE_STATE {
  MILEAGE = 'Mileage/MILEAGE'
}

export enum MILEAGE_STORE_GETTERS {
  MILEAGE = 'Mileage/MILEAGE',
  MILEAGE_ITEM = 'Mileage/MILEAGE_ITEM'
}

export enum MILEAGE_STORE_ACTIONS {
  GET_MILEAGE = 'Mileage/GET_MILEAGE',
  GET_TRIP_MILEAGE = 'Mileage/GET_TRIP_MILEAGE',
  ADD_MILEAGE_ITEM = 'Mileage/ADD_MILEAGE_ITEM',
  UPDATE_MILEAGE_ITEM = 'Mileage/UPDATE_MILEAGE_ITEM',
  DELETE_MILEAGE_ITEM = 'Mileage/DELETE_MILEAGE_ITEM'
}

export enum MILEAGE_STORE_MUTATIONS {
  SET_MILEAGE = 'Mileage/SET_MILEAGE',
  REMOVE_MILEAGE = 'Mileage/REMOVE_MILEAGE'
}

export enum MILEAGE_FIELDS {
  VEHICLE = 'vehicle',
  PLATE_NUMBER = 'plateNumber',
  START_ADDRESS = 'startingPointAddress',
  DESTINATION_ADDRESS = 'destinationAddress',
  DISTANCE = 'totalDistance',
  ROUND_TRIP = 'roundTrip',
  DATE = 'date',
  COMMENT = 'comment',
  SUM = 'sum',
  ADDITIONAL_INFO = 'additionalInfoShort'
}

export enum VEHICLE_TYPE {
  PRIVATE_CAR = 'privateCar',
  COMPANY_CAR = 'companyCar',
  MOTORBOAT = 'motorboat',
  MOTORCYCLE = 'motorcycle',
  MOPED = 'moped',
  SNOWMOBILE = 'snowmobile',
  QUADBIKE = 'quadbike',
  OTHER = 'other'
}
