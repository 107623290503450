import { validateStatus } from '@/lib/status'
import { TRIP_STATUS, TRIP_STATUS_CATEGORY, STATUS_COLORS } from '@/enums'

export default function tripCurrencyConversionStatus(trip) {
  if (validateStatus(trip.status, [TRIP_STATUS.CURRENCY_PROCESSING])) {
    return {
      color: STATUS_COLORS.BLUE,
      label: 'processingCurrency',
      status: TRIP_STATUS_CATEGORY.CURRENCY_PROCESSING
    }
  }

  if (validateStatus(trip.status, [TRIP_STATUS.CURRENCY_CONVERTED])) {
    return {
      color: STATUS_COLORS.GREEN,
      label: 'currencyConverted',
      status: TRIP_STATUS_CATEGORY.CURRENCY_CONVERTED
    }
  }

  return {}
}
