import { computed } from 'vue'
import xstore from '@/store'
import { useContext } from '@/composables'
import { PaymentMethod, PaymentMethods } from '@/models'
import { PAYMENTS_STORE_ACTIONS, PAYMENTS_STORE_GETTERS } from '@/enums'

export default function usePaymentMethods() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId) || '')

  const paymentMethods = computed<PaymentMethods>(() => {
    return xstore.getters[PAYMENTS_STORE_GETTERS.PAYMENT_METHODS][companyId.value] || {}
  })

  const paymentMethodsForUser = (userId: number | null | undefined): PaymentMethod[] => {
    if (!userId) return []
    return Object.values(paymentMethods.value).filter((method: PaymentMethod) => {
      return !method.userId || method.userId == userId
    })
  }

  const paymentMethodById = (id: string | number): PaymentMethod => {
    return paymentMethods.value?.[id] || {}
  }

  const loadPaymentMethods = () => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.GET_PAYMENT_METHODS, {
      companyId: companyId.value
    })
  }

  const createPaymentMethod = (newPaymentMethod: PaymentMethod): Promise<PaymentMethod> | undefined => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.CREATE_PAYMENT_METHOD, {
      companyId: companyId.value,
      newPaymentMethod
    })
  }

  const updatePaymentMethod = (paymentMethodId: string | number, updatedPaymentMethod: PaymentMethod) => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.UPDATE_PAYMENT_METHOD, {
      companyId: companyId.value,
      paymentMethodId,
      updatedPaymentMethod
    })
  }

  const deletePaymentMethod = (paymentMethodId: string | number) => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.DELETE_PAYMENT_METHOD, {
      companyId: companyId.value,
      paymentMethodId
    })
  }

  return {
    paymentMethods,
    paymentMethodById,
    paymentMethodsForUser,
    loadPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod
  }
}
