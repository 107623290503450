import UiSnackbar from '@ui/components/UiSnackbar'
import type { Plugin as PluginType } from 'vue'
import emitter from 'tiny-emitter/instance'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $snackbar: typeof snackbar
  }
}

export const snackbar = (
  message: string,
  type?: string,
  timeToDisappear?: number | boolean,
  bottomOffset?: number | boolean,
  options?: any
) => {
  emitter.emit('appear', message, type, timeToDisappear, bottomOffset, options)

  if (type === 'error') {
    console.log(message)
  }
}

const Plugin: PluginType = (app) => {
  app.config.globalProperties.$snackbar = snackbar

  app.component('ui-snackbar', UiSnackbar)
}

export default Plugin
