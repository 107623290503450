export enum OVERVIEW_STORE_STATE {
  TRIPS_COUNT = 'Overview/TRIPS_COUNT',
  RECEIPTS_COUNT = 'Overvoew/RECEIPTS_COUNT'
}

export enum OVERVIEW_STORE_GETTERS {
  TRIPS_COUNT = 'Overview/TRIPS_COUNT',
  RECEIPTS_COUNT = 'Overview/RECEIPTS_COUNT'
}

export enum OVERVIEW_STORE_ACTIONS {
  GET_TRIPS_COUNT = 'Overview/GET_TRIPS_COUNT',
  GET_RECEIPTS_COUNT = 'Overview/GET_RECEIPTS_COUNT',
}

export enum OVERVIEW_STORE_MUTATIONS {
  SET_TRIPS_COUNT = 'Overview/SET_TRIPS_COUNT',
  SET_RECEIPTS_COUNT = 'Overview/SET_RECEIPTS_COUNT',
}

export enum OVERVIEW_STATUS_LABELS {
  PICTURELESS = 'noPicture',
  UNSUBMITTED = 'draft',
  DUPLICATE = 'notDistinct',
  DIGITIZING = 'digitizing',
  DIGITIZED = 'digitized',
  PROCESSING = 'tripProcessing',
  APPROVING = 'approving',
  BOUNCED = 'forwardingFailed',
  SENT = 'forwardingSuccess'
}

export enum OVERVIEW_STATUS_KEYS {
  NoPicture = 'PICTURELESS',
  Unsubmitted = 'UNSUBMITTED',
  NotDistinct = 'DUPLICATE',
  Digitizing = 'DIGITIZING',
  Digitized = 'DIGITIZED',
  Processing = 'PROCESSING',
  Approving = 'APPROVING',
  Bounced = 'BOUNCED',
  Sent = 'SENT'
}

export enum OVERVIEW_STATUS_COLORS {
  PICTURELESS = 'RED',
  UNSUBMITTED = 'GREY',
  DUPLICATE = 'RED',
  DIGITIZING = 'BLUE',
  DIGITIZED = 'YELLOW',
  PROCESSING = 'BLUE',
  APPROVING = 'BLUE',
  BOUNCED = 'RED',
  SENT = 'GREEN'
}
