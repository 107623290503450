import { computed, ComputedRef } from 'vue'
import xstore from '@/store'
import {
  AccountingEntries,
  AccountingObject,
  AccountingRef,
  DisplayedAccountingEntry,
  ExpenseAccountingRefs
} from '@/models'
import { useContext } from '@/composables'
import { ACCOUNTING_OBJECT_TYPE, ACCOUNTING_STORE_ACTIONS, ACCOUNTING_STORE_GETTERS } from '@/enums'

export default function useAccountingRefs() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId))

  const allReceiptAccountingEntries: ComputedRef<AccountingEntries> = computed(() => {
    return xstore.getters[ACCOUNTING_STORE_GETTERS.RECEIPT_ACCOUNTING_ENTRIES]
  })

  const allTripAccountingEntries: ComputedRef<AccountingEntries> = computed(() => {
    return xstore.getters[ACCOUNTING_STORE_GETTERS.TRIP_ACCOUNTING_ENTRIES]
  })

  const allReceiptAccountingRefs: ComputedRef<ExpenseAccountingRefs> = computed(() => {
    return xstore.getters[ACCOUNTING_STORE_GETTERS.RECEIPT_ACCOUNTING_REFS]
  })

  const allTripAccountingRefs: ComputedRef<ExpenseAccountingRefs> = computed(() => {
    return xstore.getters[ACCOUNTING_STORE_GETTERS.TRIP_ACCOUNTING_REFS]
  })

  const receiptAccountingEntries = (receiptId: string | number) => {
    if (!receiptId) return {}
    return allReceiptAccountingEntries.value?.[Number(receiptId)]
  }

  const tripAccountingEntries = (tripId: string | number): DisplayedAccountingEntry[] => {
    if (!tripId) return []
    const entries = allTripAccountingEntries.value?.[Number(tripId)]
    if (!entries) return []
    const preparedEntries: any = {}
    entries.forEach((entry) => {
      const value = entry?.accounting?.value || 'unspecified'
      if (!preparedEntries[value]) {
        preparedEntries[value] = {
          type: entry.accounting?.type,
          name: entry.accounting?.name,
          value: value,
          totals: []
        }
      }
      preparedEntries[value].totals.push({
        currency: entry.transaction.currency,
        sum: entry.transaction.sum
      })
    })
    const preparedArray: DisplayedAccountingEntry[] = Object.values(preparedEntries)
    const unspecifiedIndex = preparedArray.findIndex((item) => item?.value === 'unspecified')
    if (unspecifiedIndex > -1) {
      preparedArray.unshift(preparedArray.splice(unspecifiedIndex, 1)[0])
    }
    return preparedArray
  }

  const receiptAccountingRefs = (receiptId: string | number) => {
    if (!receiptId) return {}
    return allReceiptAccountingRefs.value?.[Number(receiptId)]
  }

  const tripAccountingRefs = (tripId: string | number) => {
    if (!tripId) return {}
    return allTripAccountingRefs.value?.[Number(tripId)]
  }

  const loadReceiptAccountingEntries = async (receiptId: string | number) => {
    if (!companyId.value || !receiptId) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.GET_RECEIPT_ACCOUNTING_ENTRIES, {
      companyId: companyId.value,
      receiptId: Number(receiptId)
    })
  }

  const loadTripAccountingEntries = async (tripId: string | number) => {
    if (!companyId.value || !tripId) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.GET_TRIP_ACCOUNTING_ENTRIES, {
      companyId: companyId.value,
      tripId: Number(tripId)
    })
  }

  const loadReceiptAccountingRefs = async (receiptId: string | number) => {
    if (!companyId.value || !receiptId) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.GET_RECEIPT_ACCOUNTING_REFS, {
      companyId: companyId.value,
      receiptId
    })
  }

  const loadTripAccountingRefs = async (tripId: string | number) => {
    if (!companyId.value || !tripId) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.GET_TRIP_ACCOUNTING_REFS, {
      companyId: companyId.value,
      tripId
    })
  }

  const deleteAccountingRef = async (baseRefKey: string, baseRefId: string | number, accountingRef: AccountingRef) => {
    if (baseRefKey === 'tripId') {
      return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.DELETE_TRIP_ACCOUNTING_REF, {
        companyId: companyId.value,
        tripId: baseRefId,
        accountingRef
      })
    }
    if (baseRefKey === 'receiptId') {
      return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.DELETE_RECEIPT_ACCOUNTING_REF, {
        companyId: companyId.value,
        receiptId: baseRefId,
        accountingRef
      })
    }
  }

  const updateAccountingRef = async (
    accountingObjectId: AccountingObject['id'],
    accountingObjectType: ACCOUNTING_OBJECT_TYPE,
    baseRefKey: string,
    baseRefId: string | number,
    options?: AccountingRef
  ) => {
    const isTrip = baseRefKey === 'tripId'
    const allRefs = isTrip
      ? tripAccountingRefs(Number(baseRefId)) || {}
      : receiptAccountingRefs(Number(baseRefId)) || {}
    const refsToArray: AccountingRef[] = Object.values(allRefs)
    const refWithSameTypeExists = refsToArray.find(
      (refObject) =>
        refObject.refId === Number(options?.refId) &&
        refObject.refType === options?.refType &&
        refObject.accounting?.type === accountingObjectType
    )
    if (refWithSameTypeExists && options) {
      await deleteAccountingRef(baseRefKey, baseRefId, refWithSameTypeExists)
    }
    if (!accountingObjectId) return
    return xstore.dispatch(ACCOUNTING_STORE_ACTIONS.UPDATE_ACCOUNTING_REF, {
      companyId: companyId.value,
      accountingObjectId,
      baseRefKey,
      baseRefId,
      options
    })
  }

  return {
    loadReceiptAccountingEntries,
    loadTripAccountingEntries,
    loadReceiptAccountingRefs,
    loadTripAccountingRefs,
    receiptAccountingEntries,
    receiptAccountingRefs,
    tripAccountingEntries,
    tripAccountingRefs,
    updateAccountingRef
  }
}
