import { ref } from 'vue'
import Axios from 'axios'

export default function useUpdateChecker() {
  const updateRequired = ref(false)
  const latestVersion = ref(null)

  /**
   * 
   * @param interval Update check interval in minutes
   */
  const initUpdateChecker = (interval: number = 2) => {
    setInterval(async () => {
      try {
        const { data: currentVersion } = await Axios.get(`/x-application-version.info?t=${encodeURIComponent(new Date().toISOString())}`)

        if (!latestVersion.value) {
          latestVersion.value = currentVersion
        }

        if (latestVersion.value !== currentVersion) {
          updateRequired.value = true
        }
      }
      catch(e) {
        // possible connection issue, which can happen during rolling update
        console.error('ERROR LOADING APP VERSION', e)
      }
    }, interval * 60 * 1000)
  }

  return {
    updateRequired,
    initUpdateChecker
  }
}
