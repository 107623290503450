export enum RECEIPTS_STORE_STATE {
  CANCEL_TOKENS = '_Receipts/CANCEL_TOKENSA',
  EXPENSE_TYPES = '_Receipts/EXPENSE_TYPES',
  RECEIPTS = '_Receipts/RECEIPTS',
  RECEIPT_DUPLICATES = '_Receipts/RECEIPT_DUPLICATES',
  SELECTED_RECEIPTS = '_Receipts/SELECTED_RECEIPTS',
  RECEIPT_BROWSING_LIST = '_Receipts/RECEIPT_BROWSING_LIST'
}

export enum RECEIPTS_STORE_GETTERS {
  EXPENSE_TYPE_BY_ID = '_Receipts/EXPENSE_TYPE_BY_ID',
  RECEIPTS_COUNT = '_Receipts/RECEIPTS_COUNT',
  RECEIPT_BY_ID = '_Receipts/RECEIPT_BY_ID',
  EXPENSE_TYPES = '_Receipts/EXPENSE_TYPES',
  RECEIPTS = '_Receipts/RECEIPTS',
  RECEIPT_DUPLICATES = '_Receipts/RECEIPT_DUPLICATES',
  SELECTED_RECEIPTS = '_Receipts/SELECTED_RECEIPTS',
  RECEIPT_BROWSING_LIST = '_Receipts/RECEIPT_BROWSING_LIST'
}

export enum RECEIPTS_STORE_ACTIONS {
  GET_FILTERED_RECEIPTS = '_Receipts/GET_FILTERED_RECEIPTS',
  GET_RECEIPT = '_Receipts/GET_RECEIPT',
  GET_RECEIPT_DUPLICATES = '_Receipts/GET_RECEIPT_DUPLICATES',
  MARK_AS_NON_DUPLICATE = '_Receipts/MARK_AS_NON_DUPLICATE',
  UPDATE_RECEIPT_DATA = '_Receipts/UPDATE_RECEIPT_DATA',
  SEND_TO_OUTBOUND = '_Receipts/SEND_TO_OUTBOUND',
  DIGITIZE_RECEIPT = '_Receipts/DIGITIZE_RECEIPT',
  SUBMIT_RECEIPT = '_Receipts/SUBMIT_RECEIPT',
  UNSUBMIT_RECEIPT = '_Receipts/UNSUBMIT_RECEIPT',
  RESUBMIT_RECEIPT = '_Receipts/RESUBMIT_RECEIPT',
  CONFIRM_RECEIPT = '_Receipts/CONFIRM_RECEIPT',
  DELETE_RECEIPT = '_Receipts/DELETE_RECEIPT',
  DELETE_RECEIPTS_MASS = '_Receipts/DELETE_RECEIPTS_MASS',
  REMOVE_RECEIPT = '_Receipts/REMOVE_RECEIPT',
  GET_OCRDATA = '_Receipts/GET_OCRDATA',
  GET_OCR = '_Receipts/GET_OCR',
  GET_EXPENSE_TPYES = 'GET_EXPENSE_TPYES',
  EXPORT_PERIODIC = '_Receipts/EXPORT_PERIODIC',
  EXPORT_RECEIPTS = '_Receipts/EXPORT_RECEIPTS',
  DOWNLOAD_RECEIPTS = '_Receipts/DOWNLOAD_RECEIPTS',
  DOWNLOAD_RECEIPT_PDF = '_Receipts/DOWNLOAD_RECEIPT_PDF',
  DOWNLOAD_IMPORT_SOURCE_FILE = '_Receipts/DOWNLOAD_IMPORT_SOURCE_FILE',
  CREATE_RECEIPT = '_Receipts/CREATE_RECEIPT',
  UPDATE_RECEIPT_BROWSING_LIST = '_Receipts/UPDATE_RECEIPT_BROWSING_LIST'
}

export enum RECEIPTS_STORE_MUTATIONS {
  UPDATE_LOCAL_RECEIPT = '_Receipts/UPDATE_LOCAL_RECEIPT',
  REMOVE_RECEIPT = '_Receipts/REMOVE_RECEIPT',
  SET_RECEIPTS = '_Receipts/SET_RECEIPTS',
  SET_RECEIPT_VALUES = '_Receipts/SET_RECEIPT_VALUES',
  SET_RECEIPT_DUPLICATES = '_Receipts/SET_RECEIPT_DUPLICATES',
  SET_EXPENSE_TYPES = '_Receipts/SET_EXPENSE_TYPES',
  SET_SELECTED_RECEIPTS = '_Receipts/SET_SELECTED_RECEIPTS',
  CLEAR_SELECTED_RECEIPTS = '_Receipts/CLEAR_SELECTED_RECEIPTS',
  SET_RECEIPT_BROWSING_LIST = '_Receipts/SET_RECEIPT_BROWSING_LIST',
}

export enum EDITABLE_RECEIPT_KEY {
  PAYMENT_METHOD_ID = 'paymentMethodId',
  SUPPLIER_REG_NR = 'supplierRegNumber',
  SUPPLIER_VAT_NR = 'supplierVATNumber',
  BILLING_TYPE = 'billingType',
  DOCUMENT_ID = 'documentId',
  PAID_WITH = 'paidWith',
  COST_TYPE = 'costType',
  SUPPLIER = 'supplier',
  CURRENCY = 'currency',
  ITEM_ROWS = 'itemRows',
  DOC_TYPE = 'docType',
  DUE_DATE = 'dueDate',
  COMMENT = 'comment',
  REF_NUM = 'refNum',
  TRIP_ID = 'tripId',
  ISSUED = 'issued',
  IBAN = 'IBAN',
  SUM = 'sum',
  PAID = 'paid'
}

export enum DOCUMENT_TYPE {
  ATTACHMENT = 'nonexpense',
  INVOICE = 'invoice',
  RECEIPT = 'receipt'
}

export enum DOCUMENT_SUBTYPE {
  CAR_REGISTRATION_CARD = 'carRegistrationCard',
  CARD_STATEMENT = 'ccstatement'
}

export enum BILLING_TYPE {
  PERSONAL = 'personal',
  COMPANY = 'company'
}

export const BILLING_TYPE_TEXT = {
  [BILLING_TYPE.PERSONAL]: 'paidPersonally',
  [BILLING_TYPE.COMPANY]: 'paidByCompanyFunds'
}

export enum PAID_WITH {
  TRANSFER = 'transfer',
  CASH = 'cash',
  CC = 'cc'
}

export enum FIELD_TYPE {
  COST_TYPE = 'costType',
  ITEM_ROWS = 'itemRows',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  SELECT = 'select',
  TEXT = 'text',
  DATE = 'date',
  TIME = 'time',
  CHECKBOX = 'checkbox',
  CHECKMARK = 'checkmark',
  LIST = 'list'
}

export enum COST_TYPE {
  REPRESENTATION = 'representationCost',
  BUSINESS = 'businessCost',
  TRANSPORT = 'transport',
  OTHER = 'otherCost',
  CAR = 'car'
}

export enum ITEM_ROW_COLUMN_KEY {
  DESCRIPTION = 'description',
  CODE = 'code',
  UNIT = 'unit',
  QUANTITY = 'quantity',
  PRICE = 'price',
  VAT_RATE = 'vrt',
  VAT= 'vat',
  SUM = 'snv',
  TOTAL = 'sum',
  ITEM_CODE = 'itemCode',
  COST_OBJECTIVE = 'costObjective',
  PROJECT = 'project',
  ACCOUNT = 'account',
  DIMENSION = 'dimension',
  DEPARTMENT = 'department',
  VAT_CODE = 'VATCode'
}

export enum DISPLAYED_ITEM_ROW_TYPE {
  SUM_INPUT = 'SUM_INPUT',
  VAT_LINE_INPUT = 'VAT_LINE_INPUT',
  ITEM_ROW_TOTALS = 'ITEM_ROW_TOTALS'
}

export enum ITEM_ROW_KEY {
  ROW_NUM = 'rowNum',
  DESCRIPTION = 'description',
  CODE = 'code',
  UNIT = 'unit',
  QUANTITY = 'quantity',
  PRICE = 'price',
  VAT_RATE = 'VATrate',
  VAT = 'VATsum',
  SUM = 'sum',
  TOTAL = 'total'
}

export enum RECEIPT_STATUS {
  FILE_RECEIVED = 1,
  DATA_RECEIVED = 2,
  THUMB_CREATED = 4,
  OCRDONE = 8,
  DIGITIZED = 16,
  XML_CREATED = 32,
  CHANNEL_AUTO = 64,
  CHANNEL_MANUAL = 128,
  CHANNEL_CANCEL = 256,
  DEPARTED = 512,
  NOT_DISTINCT = 1024,
  FROM_EMAIL = 2048,
  FILE_UPLOAD_STARTED = 4096,
  PROCESSING = 8192,
  APPROVING = 16384,
  APPROVED = 32768,
  BOUNCED = 65536,
  SUBMITTED = 131072,
  REJECTED = 262144,
  CURRENCY_PROCESSING = 524288,
  CURRENCY_CONVERTED = 1048576,
  READY = 2097152
}

export enum RECEIPT_STATUS_CATEGORY {
  ATTACHMENT = 'ATTACHMENT',
  PROCESSING = 'PROCESSING',
  SUBMITTED = 'SUBMITTED',
  DIGITIZED = 'DIGITIZED',
  DIGITIZING = 'DIGITIZING',
  PREPARING = 'PREPARING',
  SENT = 'SENT',
  DUPLICATE = 'DUPLICATE',
  APPROVING = 'APPROVING',
  CONFIRMING = 'CONFIRMING',
  APPROVED = 'APPROVED',
  APPROVED_AND_SENT = 'APPROVED_AND_SENT',
  BOUNCED = 'BOUNCED',
  REJECTED = 'REJECTED',
  INVALID = 'INVALID',
  PICTURELESS = 'PICTURELESS',
  UNSUBMITTED = 'UNSUBMITTED',
  CURRENCY_PROCESSING = 'CURRENCY_PROCESSING',
  CURRENCY_CONVERTED = 'CURRENCY_CONVERTED'
}

export enum STATUS_COLORS {
  YELLOW = '#ffcc14',
  PURPLE = '#7a0fce',
  GREEN = '#0fce91',
  BLUE = '#0053FF',
  RED = '#F22C5A',
  GREY = '#969bb0',
  WHITE = '#ffffff'
}

export enum STATUS_BACKGROUND_COLORS {
  YELLOW = '#fff5d1',
  PURPLE = '#efdefd',
  GREEN = '#e3fdf5',
  BLUE = '#ebf2ff',
  RED = '#fee7ec',
  GREY = '#e4e7ed'
}

export enum STATUS_SEQUENCE {
  NONE = 16,
  DRAFT = 15,
  BOUNCED = 14,
  INVALID = 13,
  PICTURELESS = 12,
  DUPLICATE = 11,
  REJECTED = 10,
  PROCESSING = 9,
  DIGITIZING = 8,
  APPROVING = 7,
  DIGITIZED = 6,
  CONFIRMING = 5,
  APPROVED = 4,
  APPROVED_AND_SENT = 3,
  SENT = 2,
  ATTACHMENT = 1,
}
