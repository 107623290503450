import { computed } from 'vue'
import { RECEIPTS_STORE_ACTIONS, RECEIPTS_STORE_GETTERS } from '@/enums'
import { Receipt } from '@/models'
import xstore from '@/store'
import { useContext } from '@/composables'

export default function useDuplicates() {
  const { root } = useContext()

  const receiptId = computed(() => Number(root?.$route.params.receiptId))

  const loadDuplicates = async (companyId: any, receiptId: any): Promise<Receipt[]> => {
    return await xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_RECEIPT_DUPLICATES, {
      companyId,
      receiptId
    })
  }

  const activeReceiptDuplicates = computed(() => {
    return xstore.getters[RECEIPTS_STORE_GETTERS.RECEIPT_DUPLICATES](receiptId.value) || []
  })

  const duplicatesByReceiptId = (receiptId: number) => {
    return xstore.getters[RECEIPTS_STORE_GETTERS.RECEIPT_DUPLICATES](receiptId) || []
  }

  const markAsNonDuplicate = (companyId: any, receiptId: any) => {
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.MARK_AS_NON_DUPLICATE, {
      companyId,
      receiptId
    })
  }

  return {
    activeReceiptDuplicates,
    duplicatesByReceiptId,
    loadDuplicates,
    markAsNonDuplicate
  }
}
