export enum APPROVALS_STORE_STATE {
  APPROVALS = 'Approvals/APPROVALS',
  APPROVERS = 'Approvals/APPROVERS'
}

export enum APPROVALS_STORE_GETTERS {
  APPROVALS = 'Approvals/APPROVALS',
  APPROVAL_ITEM = 'Approvals/APPROVAL_ITEM',
  APPROVER_OPTIONS = 'Approvals/APPROVER_OPTIONS'
}

export enum APPROVALS_STORE_ACTIONS {
  GET_ALL_APPROVALS = 'Approvals/GET_ALL_APPROVALS',
  GET_APPROVAL = 'Approvals/GET_APPROVAL',
  GET_TRIP_APPROVAL = 'Approvals/GET_TRIP_APPROVAL',
  GET_RECEIPT_APPROVAL = 'Approvals/GET_RECEIPT_APPROVAL',
  GET_APPROVER_OPTIONS = 'Approvals/GET_APPROVER_OPTIONS',
  DELETE_APPROVAL = 'Approvals/DELETE_APPROVAL',
  APPROVE = 'Approvals/APPROVE',
  REJECT = 'Approvals/REJECT',
  APPEND = 'Approvals/APPEND',
  DELEGATE = 'Approvals/DELEGATE',
  ADD_DECISION = 'Approvals/ADD_DECISION',
  DELETE_DECISION = 'Approvals/DELETE_DECISION'
}

export enum APPROVALS_STORE_MUTATIONS {
  SET_APPROVALS = 'Approvals/SET_APPROVALS',
  SET_APPROVAL = 'Approvals/SET_APPROVAL',
  SET_APPROVER_OPTIONS = 'Approvals/SET_APPROVER_OPTIONS',
  DELETE_APPROVAL = 'Approvals/DELETE_APPROVAL'
}

export enum APPROVAL_STATE {
  PREPARED = 'PREPARED', // Approval or decision has been created. In case of decision, the approver has not been notified yet. Approval decision cannot be made in this state.
  DELEGATED = 'DELEGATED', // Approval has been delegated to another approver.
  PENDING = 'PENDING', // Approval / decision is in process.
  REJECTED = 'REJECTED', // Approval has been rejected, or decision has been made against.
  APPROVED = 'APPROVED' // Approval has been approved, or decision has been made for.
}

export enum APPROVAL_STATE_COLOR {
  PREPARED = '#e3e3e4',
  DELEGATED = '#e3e3e4',
  PENDING = '#ffcc14',
  REJECTED = '#f22c5a',
  APPROVED = '#0fce91'
}

export enum APPROVAL_ACTION {
  REJECT = 'reject',
  APPROVE = 'approve',
  COMMENT_AND_APPROVE = 'commentAndApprove',
  DELEGATE = 'delegateDecision',
  APPEND = 'addApprover',
  CANCEL_APPROVAL = 'cancelApproval',
  DELETE_APPROVER = 'delete',
  UNSELECT = 'unselect'
}
