import { ComputedRef, computed } from 'vue'
import { xstore } from '@/store'
import { useContext } from '@/composables'
import { TRANSACTIONS_STORE_ACTIONS, TRANSACTIONS_STORE_GETTERS } from '@/enums'
import { BankAccountInfo, PaymentTransaction } from '@/models'

export default function useTransactions() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId))

  const transactionList: ComputedRef<PaymentTransaction[]> = computed(() => {
    return Object.values(xstore.getters[TRANSACTIONS_STORE_GETTERS.TRANSACTIONS][companyId.value] || {})
  })

  const unmatchedList: ComputedRef<PaymentTransaction[]> = computed(() => {
    return transactionList.value.filter((transaction: PaymentTransaction) => {
      return !transaction.receiptId
    })
  })

  const unmatchedCount = computed<number>(() => {
    return unmatchedList.value.length
  })

  const transactionById = (transactionId: string | number): PaymentTransaction | undefined => {
    if (!companyId.value || !transactionId) return
    return xstore.getters[TRANSACTIONS_STORE_GETTERS.TRANSACTIONS]?.[companyId.value]?.[transactionId]
  }

  const importFromFile = (e: Event) => {
    const formData = new FormData()
    const target = e.target as HTMLInputElement
    const files = target.files
    const uploadedFile = files ? files[0] : null
    if (uploadedFile) {
      formData.append('file', uploadedFile)
    }
    return xstore.dispatch(TRANSACTIONS_STORE_ACTIONS.IMPORT_TRANSACTIONS_FROM_FILE, {
      companyId: companyId.value,
      formData
    })
  }

  const loadTransactions = (): Promise<PaymentTransaction[]> | undefined => {
    if (!companyId.value) return
    return xstore.dispatch(TRANSACTIONS_STORE_ACTIONS.GET_TRANSACTIONS, {
      companyId: companyId.value
    })
  }

  const autogenerateReports = (): Promise<PaymentTransaction[]> | undefined => {
    if (!companyId.value) return
    return xstore.dispatch(TRANSACTIONS_STORE_ACTIONS.AUTOGENERATE_REPORTS, {
      companyId: companyId.value
    })
  }

  const updateTransaction = (transactionId: string | number, values: {}): Promise<PaymentTransaction> | undefined => {
    if (!companyId.value || !transactionId) return
    return xstore.dispatch(TRANSACTIONS_STORE_ACTIONS.UPDATE_TRANSACTION, {
      companyId: companyId.value,
      transactionId,
      values
    })
  }

  const deleteTransaction = (transactionId: string | number): Promise<string> | undefined => {
    if (!companyId.value || !transactionId) return

    // TODO: Fix return type or remove explicit type from function
    // @ts-ignore
    return xstore.dispatch(TRANSACTIONS_STORE_ACTIONS.DELETE_TRANSACTION, {
      companyId: companyId.value,
      transactionId
    })
  }

  return {
    transactionList,
    transactionById,
    importFromFile,
    loadTransactions,
    autogenerateReports,
    updateTransaction,
    deleteTransaction,
    unmatchedCount
  }
}
