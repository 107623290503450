import moment from 'moment'
import { each, uniq, uniqBy, cloneDeep } from 'lodash-es'
import { TRAVEL_REPORT_FIELDS } from '@/enums'
import { COUNTRY_CODE, Trip, CountryNames, TripDestination } from '@/models'
import countrynametranslations from '@/lib/countrynametranslations/countrynames'
import specialTerritories from '@/lib/allowance/data/specialTerritories'
import allowance from '@/lib/allowance/allowance'
import { allowanceCalculator } from '@/lib/allowance'
import world from '@/lib/world'
import useTrips from './useTrips'

export default function useTravelReportFields() {
  const countries: CountryNames = countrynametranslations

  const featuredCountries = () => {
    const { companyTrips } = useTrips()
    let featuredCountries: Array<{ title: string; value: string }> = []
    let trips = Object.values(companyTrips.value).slice(0, 100)
    // FIND ALL TRAVEL REPORTS THAT HAVE DESTINATION SET TODO: should find in daily allowance entries
    each(trips, (trip: Trip) => {
      if (trip.type === 'travel') {
        if (trip.dailyAllowanceInfo && trip.dailyAllowanceInfo.destination) {
          let destination = trip.dailyAllowanceInfo.destination
          featuredCountries.push(destination)
        }
      }
    })
    // MAKE SURE FEATURED COUNTRIES ARE UNIQUE
    featuredCountries = uniq(featuredCountries)
    // ONLY SHOW LAST FIVE
    if (featuredCountries.length > 5) {
      let length = featuredCountries.length
      featuredCountries = featuredCountries.slice(length - 5, length)
    }
    return featuredCountries.map((country: any) => {
      return { title: countries[localStorage.language || 'eng'][country], value: country }
    })
  }

  const mapDestinationOptions = (countryCode: COUNTRY_CODE) => {
    let destinationCountries: Array<{ title: string; value: string }> = []
    let surroundingCountries: Array<{ title: string; value: string }> = []
    let lang = localStorage.language || 'eng'
    if (lang === 'null') {
      // FOR PRELIVE GLITCH
      lang = 'eng'
    }
    world.forEach((country) => {
      let countryName = countries[lang][country.code]
      if (countryName && ['EE', 'FI', 'LV', 'PL', 'LT', 'RU', 'DE', 'SE'].includes(country.code)) {
        surroundingCountries.push({ title: countryName, value: country.code })
      } else if (countryName) {
        destinationCountries.push({ title: countryName, value: country.code })
      }
    })
    // sort country names in alphabetical order in user language
    destinationCountries.sort((a: { title: string }, b: { title: string }) => a.title.localeCompare(b.title))
    // at the top of the list add surrounding countries
    destinationCountries = surroundingCountries.concat(destinationCountries)
    // at the top of the list add featured countries and make sure they don't repeat
    const featured = featuredCountries()
    destinationCountries = featured.concat(destinationCountries)
    destinationCountries = uniqBy(destinationCountries, 'value')
    // add special territories
    // @ts-ignore
    const hasSpecialTerritories: CountryNames = specialTerritories[countryCode]
    if (hasSpecialTerritories) {
      Object.keys(hasSpecialTerritories).forEach((cc) => {
        let countryIndex = destinationCountries.findIndex((option) => option.value === cc)
        let territories = Object.keys(hasSpecialTerritories[cc]).map((territory) => {
          let territoryTranslation = hasSpecialTerritories[cc][territory][lang]
          return { value: territory, title: '- ' + territoryTranslation }
        })
        territories.forEach((ter) => destinationCountries.splice(countryIndex + 1, 0, ter))
      })
    }
    return destinationCountries
  }

  const canHaveDailyAllowance = (countryCode: COUNTRY_CODE, destinations: any[]): Boolean => {
    if (countryCode === 'EE') {
      return destinations?.[0]?.destination !== 'EE'
    }
    return ['FI', 'LV', 'SE', 'PL', 'LT'].includes(countryCode)
  }

  const tripFieldsByCountry = (countryCode: COUNTRY_CODE) => {
    switch (countryCode) {
      case 'EE':
        return {
          canAddDestination: false,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [
            TRAVEL_REPORT_FIELDS.DESTINATION,
            TRAVEL_REPORT_FIELDS.START_DATE,
            TRAVEL_REPORT_FIELDS.END_DATE,
            TRAVEL_REPORT_FIELDS.MAX_ALLOWANCE
          ],
          additionalFields: [TRAVEL_REPORT_FIELDS.DEPART_BEFORE_3, TRAVEL_REPORT_FIELDS.DEPART_AFTER_22],
          oneDestinationFields: {
            id: '',
            index: '',
            days: 0,
            sum: 0,
            currency: 'EUR',
            searchInput: '',
            isDestinationValid: true,
            isStartDateValid: true,
            isEndDateValid: true
          },
          calculateAllowance: (
            tripDestinations: Array<any>,
            extraInfo: any,
            tripId: string | number,
            companyMaxAllowance: any = ''
          ) => {
            let updatedDestinations = [...tripDestinations]
            let updatedExtraInfo = { ...extraInfo }

            let newTrip = {
              type: 'travel',
              destination: tripDestinations[0].destination,
              startDate: tripDestinations[0].startDate,
              endDate: tripDestinations[tripDestinations.length - 1].endDate,
              id: tripId || '',
              includesDailyAllowance: true,
              extraData: {
                departBefore03: extraInfo.departBefore03,
                departAfter22: extraInfo.departAfter22,
                maxAllowance: ''
              }
            }

            if (companyMaxAllowance !== '' && !isNaN(companyMaxAllowance)) {
              newTrip.extraData.maxAllowance = companyMaxAllowance
            }

            if (extraInfo.maxAllowance !== '' && !isNaN(extraInfo.maxAllowance)) {
              updatedExtraInfo.maxAllowance = extraInfo.maxAllowance > 50 ? 50 : extraInfo.maxAllowance
              newTrip.extraData.maxAllowance = extraInfo.maxAllowance
            }

            let dailyAllowance: any = allowance.calculate(newTrip, {}, 'EE')
            updatedDestinations[0].sum = dailyAllowance.sum || 0
            updatedDestinations[0].days = dailyAllowance.days || 0

            if (dailyAllowance.maxAllowance && companyMaxAllowance === '') {
              updatedExtraInfo.maxAllowance = dailyAllowance.maxAllowance
            }

            return { updatedDestinations, updatedExtraInfo }
          }
        }
      case 'LV':
        return {
          canAddDestination: true,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [
            TRAVEL_REPORT_FIELDS.DESTINATION,
            TRAVEL_REPORT_FIELDS.START_DATE,
            TRAVEL_REPORT_FIELDS.END_DATE,
            TRAVEL_REPORT_FIELDS.MAX_ALLOWANCE
          ],
          additionalFields: [
            TRAVEL_REPORT_FIELDS.MEAL_AND_ACCOMODATION,
            TRAVEL_REPORT_FIELDS.RETURN_OVERNIGHT,
            TRAVEL_REPORT_FIELDS.CALCULATE_WEEKENDS
          ],
          oneDestinationFields: {
            id: '',
            index: '',
            days: 0,
            sum: 0,
            currency: 'EUR',
            searchInput: '',
            isDestinationValid: true,
            isStartDateValid: true,
            isEndDateValid: true
            // mealAndAccomodation: [],
            // returnOvernight: [],
          },
          calculateAllowance: (
            tripDestinations: Array<any>,
            extraInfo: any,
            tripId: string | number,
            companyMaxAllowance: any = ''
          ) => {
            let updatedDestinations = [...tripDestinations]

            const newTrip: any = {
              id: tripId || '',
              includesDailyAllowance: true,
              startDate: tripDestinations[0].startDate,
              endDate: tripDestinations[tripDestinations.length - 1].endDate,
              dailyAllowanceInfo: {
                destinations: [],
                mealAndAccomodation: extraInfo.mealAndAccomodation,
                returnOvernight: extraInfo.returnOvernight,
                excludeWeekends: !extraInfo.calculateWeekends
              }
            }

            for (let [index, dest] of tripDestinations.entries()) {
              newTrip.dailyAllowanceInfo.destinations.push({
                index: index,
                destination: dest.destination,
                startDate: moment(dest.startDate).format('YYYY-MM-DD'),
                endDate: moment(dest.endDate).format('YYYY-MM-DD')
              })
            }

            if (companyMaxAllowance && newTrip) {
              newTrip.dailyAllowanceInfo.maxAllowance = Number(companyMaxAllowance)
            }

            if (extraInfo.maxAllowance !== '' && !isNaN(extraInfo.maxAllowance) && newTrip) {
              newTrip.dailyAllowanceInfo.maxAllowance = extraInfo.maxAllowance
            }

            let dailyAllowance: any = allowance.calculate(newTrip, {}, 'LV')

            if (dailyAllowance.dataPerDestination) {
              dailyAllowance.dataPerDestination.forEach((dataItem: { sum: number; days: number; index: number }) => {
                updatedDestinations[dataItem.index].sum = dataItem.sum || 0
                updatedDestinations[dataItem.index].days = dataItem.days || 0
              })
            }
            return { updatedDestinations, updatedExtraInfo: extraInfo }
          }
        }
      case 'FI':
        return {
          canAddDestination: true,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [
            TRAVEL_REPORT_FIELDS.DESTINATION,
            TRAVEL_REPORT_FIELDS.START_DATE,
            TRAVEL_REPORT_FIELDS.START_TIME,
            TRAVEL_REPORT_FIELDS.END_DATE,
            TRAVEL_REPORT_FIELDS.END_TIME,
            TRAVEL_REPORT_FIELDS.MAX_ALLOWANCE
          ],
          additionalFields: [TRAVEL_REPORT_FIELDS.ONE_FREE_MEAL, TRAVEL_REPORT_FIELDS.TWO_FREE_MEALS],
          freeMealsButton: true,
          oneDestinationFields: {
            id: '',
            index: '',
            days: 0,
            sum: 0,
            currency: 'EUR',
            searchInput: '',
            isDestinationValid: true,
            isStartDateValid: true,
            isEndDateValid: true,
            isStartTimeValid: true,
            isEndTimeValid: true,
            oneFreeMeal: [],
            twoFreeMeals: []
          },
          calculateAllowance: (
            tripDestinations: Array<TripDestination>,
            extraInfo: any,
            tripId: string | number,
            companyMaxAllowance: any = ''
          ) => {
            const updatedDestinations = cloneDeep(tripDestinations)

            let maxAllowance = 0
            if (companyMaxAllowance) {
              maxAllowance = Number(companyMaxAllowance)
            }
            if (extraInfo.maxAllowance) {
              maxAllowance = Number(extraInfo.maxAllowance)
            }

            for (let i = 0; i < updatedDestinations.length; i++) {
              updatedDestinations[i].index = i
            }

            const tripInput = {
              id: tripId,
              destinations: tripDestinations,
              maxAllowance
            }

            const allowanceInfo: any = allowanceCalculator('FI', tripInput, {})

            if (allowanceInfo.dataPerDestination) {
              allowanceInfo.dataPerDestination?.forEach((dataItem: any) => {
                if (dataItem.index !== undefined) {
                  updatedDestinations[dataItem.index].sum = dataItem.sum || 0
                  updatedDestinations[dataItem.index].days = dataItem.days || 0
                }
              })
            }
            return { updatedDestinations, updatedExtraInfo: extraInfo }
          }
        }
      case 'SE':
        return {
          canAddDestination: true,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [
            TRAVEL_REPORT_FIELDS.DESTINATION,
            TRAVEL_REPORT_FIELDS.START_DATE,
            TRAVEL_REPORT_FIELDS.START_TIME,
            TRAVEL_REPORT_FIELDS.END_DATE,
            TRAVEL_REPORT_FIELDS.END_TIME
          ],
          additionalFields: [
            TRAVEL_REPORT_FIELDS.BREAKFAST_INCLUDED,
            TRAVEL_REPORT_FIELDS.LUNCH_INCLUDED,
            TRAVEL_REPORT_FIELDS.DINNER_INCLUDED
          ],
          oneDestinationFields: {
            id: '',
            index: '',
            days: 0,
            sum: 0,
            currency: 'SEK',
            searchInput: '',
            isDestinationValid: true,
            isStartDateValid: true,
            isEndDateValid: true,
            isStartTimeValid: true,
            isEndTimeValid: true
            // breakfastIncluded: [],
            // lunchIncluded: [],
            // dinnerIncluded: []
          },
          calculateAllowance: (
            tripDestinations: Array<any>,
            extraInfo: any,
            tripId: string | number,
            companyMaxAllowance: any = ''
          ) => {
            let updatedDestinations = [...tripDestinations]

            const newTrip: any = {
              includesDailyAllowance: true,
              destinations: [],
              breakfastIncluded: extraInfo.breakfastIncluded,
              lunchIncluded: extraInfo.lunchIncluded,
              dinnerIncluded: extraInfo.dinnerIncluded
            }

            for (let [index, dest] of tripDestinations.entries()) {
              newTrip.destinations.push({
                index: index,
                destination: dest.destination,
                startDate: moment(dest.startDate).format('YYYY-MM-DD'),
                endDate: moment(dest.endDate).format('YYYY-MM-DD'),
                startTime: dest.startTime,
                endTime: dest.endTime
              })
            }

            newTrip.startDate = tripDestinations[0].startDate
            newTrip.endDate = tripDestinations[tripDestinations.length - 1].endDate

            if (companyMaxAllowance && newTrip) {
              newTrip.maxAllowance = Number(companyMaxAllowance)
            }

            newTrip.id = tripId

            let dailyAllowance: any = allowance.calculate(newTrip, {}, 'SE')
            if (dailyAllowance.dataPerDestination) {
              dailyAllowance.dataPerDestination.forEach((dataItem: { sum: number; days: number; index: number }) => {
                updatedDestinations[dataItem.index].sum = dataItem.sum || 0
                updatedDestinations[dataItem.index].days = dataItem.days || 0
              })
            }
            return { updatedDestinations, updatedExtraInfo: extraInfo }
          }
        }
      case 'LT':
        return {
          canAddDestination: true,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [TRAVEL_REPORT_FIELDS.DESTINATION, TRAVEL_REPORT_FIELDS.START_DATE, TRAVEL_REPORT_FIELDS.END_DATE],
          additionalFields: [TRAVEL_REPORT_FIELDS.NEXT_TRIP_ON_LAST_DAY],
          calculateAllowance: (
            tripDestinations: Array<any>,
            extraInfo: any,
            tripId: string | number,
            companyMaxAllowance: any = ''
          ) => {
            let updatedDestinations = [...tripDestinations]

            const newTrip: any = {
              id: tripId || '',
              includesDailyAllowance: true,
              startDate: tripDestinations[0].startDate,
              endDate: tripDestinations[tripDestinations.length - 1].endDate,
              dailyAllowanceInfo: {
                destinations: [],
                nextTripOnLastDay: extraInfo.nextTripOnLastDay
              }
            }

            for (let [index, dest] of tripDestinations.entries()) {
              newTrip.dailyAllowanceInfo.destinations.push({
                index: index,
                destination: dest.destination,
                startDate: moment(dest.startDate).format('YYYY-MM-DD'),
                endDate: moment(dest.endDate).format('YYYY-MM-DD')
              })
            }

            if (companyMaxAllowance) {
              newTrip.dailyAllowanceInfo.maxAllowance = Number(companyMaxAllowance)
            }

            let dailyAllowance: any = allowance.calculate(newTrip, {}, 'LT')
            if (dailyAllowance.dataPerDestination) {
              dailyAllowance.dataPerDestination.forEach((dataItem: { sum: number; days: number; index: number }) => {
                updatedDestinations[dataItem.index].sum = dataItem.sum || 0
                updatedDestinations[dataItem.index].days = dataItem.days || 0
              })
            }
            return { updatedDestinations, updatedExtraInfo: extraInfo }
          },
          oneDestinationFields: {
            id: '',
            index: '',
            days: 0,
            sum: 0,
            currency: 'EUR',
            searchInput: '',
            isDestinationValid: true,
            isStartDateValid: true,
            isEndDateValid: true
          }
        }
      case 'PL':
        return {
          canAddDestination: true,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [
            TRAVEL_REPORT_FIELDS.DESTINATION,
            TRAVEL_REPORT_FIELDS.START_DATE,
            TRAVEL_REPORT_FIELDS.START_TIME,
            TRAVEL_REPORT_FIELDS.END_DATE,
            TRAVEL_REPORT_FIELDS.END_TIME
          ],
          additionalFields: [
            TRAVEL_REPORT_FIELDS.BREAKFAST_INCLUDED,
            TRAVEL_REPORT_FIELDS.LUNCH_INCLUDED,
            TRAVEL_REPORT_FIELDS.DINNER_INCLUDED
          ],
          freeMealsButton: true,
          oneDestinationFields: {
            id: '',
            index: '',
            days: 0,
            sum: 0,
            currency: 'PLN',
            searchInput: '',
            isDestinationValid: true,
            isStartDateValid: true,
            isEndDateValid: true,
            isStartTimeValid: true,
            isEndTimeValid: true,
            breakfastIncluded: [],
            lunchIncluded: [],
            dinnerIncluded: []
          },
          calculateAllowance: (
            tripDestinations: Array<TripDestination>,
            extraInfo: any,
            tripId: string | number,
            companyMaxAllowance: any = ''
          ) => {
            const updatedDestinations = cloneDeep(tripDestinations)

            const newTrip: any = {
              id: tripId,
              includesDailyAllowance: true,
              destinations: [...tripDestinations],
              startDate: tripDestinations[0].startDate,
              endDate: tripDestinations[tripDestinations.length - 1].endDate
            }

            const dailyAllowance: any = allowance.calculate(newTrip, {}, 'PL')

            if (dailyAllowance.dataPerDestination) {
              dailyAllowance.dataPerDestination.forEach((dataItem: TripDestination) => {
                if (dataItem?.index !== undefined) {
                  updatedDestinations[dataItem.index] = { ...dataItem }
                }
              })
            }

            return { updatedDestinations, updatedExtraInfo: extraInfo }
          }
        }
      default:
        return {
          canAddDestination: true,
          destinationOptions: mapDestinationOptions(countryCode),
          fields: [TRAVEL_REPORT_FIELDS.DESTINATION, TRAVEL_REPORT_FIELDS.START_DATE, TRAVEL_REPORT_FIELDS.END_DATE],
          additionalFields: [],
          oneDestinationFields: {}
        }
    }
  }

  const filterDestinationSuggestions = (searchInput: string, allOptions: Array<any>) => {
    if (!searchInput) {
      return [...allOptions]
    }
    return allOptions.filter((option) => {
      return option.title && option.title.toLowerCase().includes(searchInput.toLowerCase())
    })
  }

  return {
    canHaveDailyAllowance,
    tripFieldsByCountry,
    filterDestinationSuggestions
  }
}
