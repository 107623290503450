import UiNotification from '@ui/components/UiNotification'
import type { Plugin as PluginType } from 'vue'
import emitter from 'tiny-emitter/instance'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $notification: typeof notification
  }
}

export const notification = (
  message: string,
  type?: string,
  timeToDisappear?: number,
  bottomOffset?: number,
  options?: any
) => {
  emitter.emit('appear', message, type, timeToDisappear, bottomOffset, options)

  if (type === 'error') {
    console.log(message)
  }
}

const Plugin: PluginType = (app) => {
  app.config.globalProperties.$notification = notification

  app.component('ui-notification', UiNotification)
}

export default Plugin