export enum COMPANIES_STORE_STATE {
  COMPANIES = 'Companies/COMPANIES',
  ACTIVE_COMPANY = 'Companies/ACTIVE_COMPANY'
}

export enum COMPANIES_STORE_GETTERS {
  COMPANIES = 'Companies/COMPANIES',
  ACTIVE_COMPANY = 'Companies/ACTIVE_COMPANY',
  COMPANY_BY_ID = 'Companies/COMPANY_BY_ID'
}

export enum COMPANIES_STORE_ACTIONS {
  GET_COMPANIES = 'Companies/GET_COMPANIES',
  GET_COMPANY_ROLES = 'Companies/GET_COMPANY_ROLES',
  SEND_INVITE = 'Companies/SEND_INVITE',
  REMOVE_COMPANY_ROLE = 'Companies/REMOVE_COMPANY_ROLE',
  SET_ACTIVE_COMPANY_BY_ID = 'Companies/SET_ACTIVE_COMPANY_BY_ID',
  GET_COMPANY = 'Companies/GET_COMPANY',
  UPDATE_SETTINGS = 'Companies/UPDATE_SETTINGS'
}

export enum COMPANIES_STORE_MUTATIONS {
  SET_COMPANIES = 'Companies/SET_COMPANIES',
  SET_COMPANY_VALUES = 'Companies/SET_COMPANY_VALUES',
  SET_COMPANY_SETTINGS = 'Companies/SET_COMPANY_SETTINGS'
}

export enum PRICING_PLAN {
  PAY_AS_YOU_GO = 'pay-as-you-go',
  PLATINUM = '11',
  GOLD = '14',
  FREE_DEMO = '15'
}

export enum CARD_REPORTING_INTERVAL {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export enum UNSUBMITTED_EXPENSE_REPORT_REMINDER_START {
  END_OF_MONTH = 'endOfMonth',
  BEGINNING_OF_MONTH = 'beginningOfMonth'
}
