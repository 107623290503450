import {
  Router,
  RouteLocationNormalized,
  NavigationGuardNext,
  createWebHistory,
  createRouter,
} from 'vue-router'

import routes from './cloud.routes'
import {Config} from '@/types'

export default (config: Config): Router => {
  console.log('creating router @ ' + config?.appRoot)

  const router = createRouter({
    history: createWebHistory(config?.appRoot),
    routes
  })
  
  router.beforeEach((
    to: RouteLocationNormalized,
    from: RouteLocationNormalized, 
    next: NavigationGuardNext
  ) => {
    let redirect
  
    if (to.path.startsWith('/@')) {
      redirect = to.path
        .replace(/^\/@/, '/cloud')
    }
  
    if (redirect) {
      next(redirect)
    } else {
      next()
    }
  })

  return router
}