export enum E_INVOICING_INTEGRATION_STATUS {
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE'
}

export enum E_INVOICING_SETTINGS {
  EINVOICE_PREFER_ROW_GROUPS = 'EINVOICE_PREFER_ROW_GROUPS',
  EINVOICE_NOTIFICATIONS = 'EINVOICE_NOTIFICATIONS',
  EINVOICE_DISABLE_AUTOACCEPT = 'EINVOICE_DISABLE_AUTOACCEPT'
}

export enum E_INVOICING_WARNING {
  EINVOICING_CONTRACT_ALREADY_EXISTS = 'EINVOICING_CONTRACT_ALREADY_EXISTS',
  COMPANY_REQ_EXISTS = 'COMPANY_REQ_EXISTS'
}
