import { computed, ComputedRef } from 'vue'
import { MILEAGE_FIELDS, FIELD_TYPE, ACCOUNTING_OBJECT_TYPE, REFERENCE_OBJECT_TYPE } from '@/enums'
import { AccountingObject, AccountingRef, MileageItem } from '@/models'
import {
  useContext,
  useCompanies,
  useAccountingRefs,
  useAccountingSettings,
  useAccountingFields,
  useInstanceHelpers,
  useFormatters
} from '@/composables'

export default function useMileageFields() {
  const { t } = useInstanceHelpers()
  const { root } = useContext()
  const { activeCompany } = useCompanies()
  const { activeAccountingFields, accountingEntriesEnabled } = useAccountingSettings()
  const { tripAccountingRefs, updateAccountingRef } = useAccountingRefs()
  const { accountingFieldOptions } = useAccountingFields()
  const { formatSum, formatInteger, formatDateSlashes } = useFormatters()

  const tripId = computed(() => Number(root?.$route.params.tripId))
  const countryCode = computed(() => activeCompany.value?.countryCode || '')
  const activeRefs = computed(() => tripAccountingRefs(tripId.value || '') || {})

  const activeMileageRefs: ComputedRef<AccountingRef[]> = computed(() => {
    return Object.values(activeRefs.value || {}).filter(({ refType }) => {
      return refType === REFERENCE_OBJECT_TYPE.TRIP_MILEAGE
    })
  })

  const mileageAccountingFields = computed(() => {
    if (!accountingEntriesEnabled.value) return []
    const mileageFields = activeAccountingFields.value?.find(({ target }) => {
      return target === REFERENCE_OBJECT_TYPE.TRIP_MILEAGE
    })
    return mileageFields?.types || []
  })

  const accountingFieldVisible = (fieldType: ACCOUNTING_OBJECT_TYPE) => {
    if (!accountingEntriesEnabled) return false
    const hasActiveRef = Boolean(
      activeMileageRefs.value.find(({ accounting }) => {
        return accounting?.type === fieldType
      })
    )
    return mileageAccountingFields.value.includes(fieldType) || hasActiveRef
  }

  const displayVehicleName = (vehicle: string) => {
    if (countryCode.value === 'FI' || countryCode.value === 'EE') {
      return t(vehicle)
    }
    const ownerTranslation = {
      private: 'ownedByPerson',
      company: 'ownedByCompany'
    }
    const [owner, type] = vehicle.split(' ')
    //@ts-ignore
    return `${t(type)} - ${t(ownerTranslation[owner])}`
  }

  const mileageFields = computed(() => {
    const allFields = [
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ITEM_CODE],
        type: ACCOUNTING_OBJECT_TYPE.ITEM_CODE,
        label: t(ACCOUNTING_OBJECT_TYPE.ITEM_CODE),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.ITEM_CODE),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ITEM_CODE].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.ITEM_CODE, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem?.id
          })
        }
      },
      {
        type: MILEAGE_FIELDS.VEHICLE,
        label: t(MILEAGE_FIELDS.VEHICLE),
        inputType: false,
        isVisible: true,
        getValue: (mileageItem: MileageItem) => displayVehicleName(mileageItem?.info?.vehicle || ''),
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.PLATE_NUMBER,
        label: t(MILEAGE_FIELDS.PLATE_NUMBER),
        inputType: false,
        isVisible: ['EE', 'PL'].includes(countryCode.value),
        getValue: (mileageItem: MileageItem) => mileageItem?.info?.plateNumber,
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.START_ADDRESS,
        label: t(MILEAGE_FIELDS.START_ADDRESS),
        inputType: false,
        isVisible: ['FI', 'PL'].includes(countryCode.value),
        getValue: (mileageItem: MileageItem) => mileageItem?.startPointAddress,
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.DESTINATION_ADDRESS,
        label: t(MILEAGE_FIELDS.DESTINATION_ADDRESS),
        inputType: false,
        isVisible: ['FI', 'PL'].includes(countryCode.value),
        getValue: (mileageItem: MileageItem) => mileageItem?.endPointAddress,
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.DISTANCE,
        label: t(MILEAGE_FIELDS.DISTANCE),
        inputType: false,
        isVisible: true,
        getValue: (mileageItem: MileageItem) => formatInteger(mileageItem?.distance || 0),
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.ROUND_TRIP,
        label: t(MILEAGE_FIELDS.ROUND_TRIP),
        inputType: FIELD_TYPE.CHECKMARK,
        isVisible: countryCode.value === 'FI',
        getValue: (mileageItem: MileageItem) => mileageItem?.info?.roundTrip,
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.ADDITIONAL_INFO,
        label: t(MILEAGE_FIELDS.ADDITIONAL_INFO),
        inputType: FIELD_TYPE.LIST,
        isVisible: countryCode.value === 'FI',
        getValue: (mileageItem: MileageItem) => {
          const list = []
          if (mileageItem.info.over50hp) {
            list.push(t('moreThan50hp'))
          }
          if (mileageItem.info.withCanteen) {
            list.push(`+ ${t('canteenOrEqual')}`)
          }
          if (mileageItem.info.withCaravan) {
            list.push(`+ ${t('caravan')}`)
          }
          if (mileageItem.info.withDog) {
            list.push(`+ ${t('serviceDog')}`)
          }
          if (mileageItem.info.withHeavyLoad) {
            list.push(`+ ${t('machineryOrLoad')}`)
          }
          if (mileageItem.info.withTrailer) {
            list.push(`+ ${t('trailer')}`)
          }
          if (mileageItem.info.roughRoadDistance) {
            list.push(`${t('forrestRoadDistance')}: ${mileageItem.info.roughRoadDistance} km`)
          }
          if (mileageItem.info.coworkers) {
            list.push(`${t('numberOfCoworkers')}: ${mileageItem.info.coworkers}`)
          }
          return list
        },
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.DATE,
        label: t(MILEAGE_FIELDS.DATE),
        inputType: false,
        isVisible: true,
        getValue: (mileageItem: MileageItem) => formatDateSlashes(mileageItem?.info?.date || ''),
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.COMMENT,
        label: t(MILEAGE_FIELDS.COMMENT),
        inputType: false,
        isVisible: true,
        getValue: (mileageItem: MileageItem) => mileageItem?.comment,
        handleOnChange: () => {}
      },
      {
        type: MILEAGE_FIELDS.SUM,
        label: t(MILEAGE_FIELDS.SUM),
        inputType: false,
        isVisible: true,
        getValue: (mileageItem: MileageItem) => formatSum(mileageItem?.sum || 0),
        handleOnChange: () => {}
      },
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.VAT_CODE],
        type: ACCOUNTING_OBJECT_TYPE.VAT_CODE,
        label: t(ACCOUNTING_OBJECT_TYPE.VAT_CODE),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.VAT_CODE),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.VAT_CODE].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.VAT_CODE, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem.id
          })
        }
      },
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ACCOUNT],
        type: ACCOUNTING_OBJECT_TYPE.ACCOUNT,
        label: t(ACCOUNTING_OBJECT_TYPE.ACCOUNT),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.ACCOUNT),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.ACCOUNT].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.ACCOUNT, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem.id
          })
        }
      },
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.PROJECT],
        type: ACCOUNTING_OBJECT_TYPE.PROJECT,
        label: t(ACCOUNTING_OBJECT_TYPE.PROJECT),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.PROJECT),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.PROJECT].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.PROJECT, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem.id
          })
        }
      },
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DIMENSION],
        type: ACCOUNTING_OBJECT_TYPE.DIMENSION,
        label: t(ACCOUNTING_OBJECT_TYPE.DIMENSION),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.DIMENSION),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DIMENSION].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.DIMENSION, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem.id
          })
        }
      },
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE],
        type: ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE,
        label: t(ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem.id
          })
        }
      },
      {
        ...accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DEPARTMENT],
        type: ACCOUNTING_OBJECT_TYPE.DEPARTMENT,
        label: t(ACCOUNTING_OBJECT_TYPE.DEPARTMENT),
        inputType: FIELD_TYPE.SELECT,
        isVisible: accountingFieldVisible(ACCOUNTING_OBJECT_TYPE.DEPARTMENT),
        getValue: ({ id }: MileageItem) => {
          return accountingFieldOptions.value[ACCOUNTING_OBJECT_TYPE.DEPARTMENT].getValue(
            activeRefs.value,
            REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            id
          )
        },
        handleOnChange: (value: AccountingObject['id'], mileageItem: MileageItem) => {
          return updateAccountingRef(value, ACCOUNTING_OBJECT_TYPE.DEPARTMENT, 'tripId', tripId.value, {
            refType: REFERENCE_OBJECT_TYPE.TRIP_MILEAGE,
            refId: mileageItem.id
          })
        }
      }
    ]
    return allFields.filter((field) => field.isVisible)
  })

  return {
    mileageFields
  }
}
