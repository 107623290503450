export enum TRANSACTIONS_STORE_STATE {
  TRANSACTIONS = 'Transactions/TRANSACTIONS',
  BANK_ACCOUNT_LIST = 'Transactions/BANK_ACCOUNT_LIST'
}

export enum TRANSACTIONS_STORE_GETTERS {
  TRANSACTIONS = 'Transactions/TRANSACTIONS',
  BANK_ACCOUNT_LIST = 'Transactions/BANK_ACCOUNT_LIST'
}

export enum TRANSACTIONS_STORE_ACTIONS {
  GET_TRANSACTIONS = 'Transactions/GET_TRANSACTIONS',
  UPDATE_TRANSACTION = 'Transactions/UPDATE_TRANSACTION',
  DELETE_TRANSACTION = 'Transaction/DELETE_TRANSACTION',
  AUTOGENERATE_REPORTS = 'Transactions/UTOGENERATE_REPORTS',
  IMPORT_TRANSACTIONS_FROM_FILE = 'Transactions/IMPORT_TRANSACTIONS_FROM_FILE',
  GET_BANK_ACCOUNT_LIST = 'Transactions/GET_BANK_ACCOUNT_LIST'
}

export enum TRANSACTIONS_STORE_MUTATIONS {
  SET_TRANSACTIONS = 'Transactions/SET_TRANSACTIONS',
  UPDATE_TRANSACTION = 'Transactions/UPDATE_TRANSACTION',
  DELETE_TRANSACTION = 'Transactions/DELETE_TRANSACTION',
  SET_BANK_ACCOUNT_LIST = 'Transactions/SET_BANK_ACCOUNT_LIST'
}

export enum TRANSACTION_WARNING_CODENAME {
  CARD_UNIDENTIFIABLE = 'CARD_UNIDENTIFIABLE',
  STATEMENT_WITHIN_SUBMITTED_REPORT = 'STATEMENT_WITHIN_SUBMITTED_REPORT',
  PAYMENT_METHOD_NOT_FOUND = 'PAYMENT_METHOD_NOT_FOUND',
  MULTIPLE_PAYMENT_METHODS_FOUND = 'MULTIPLE_PAYMENT_METHODS_FOUND'
}

export const TRANSACTION_WARNING =  {
  [TRANSACTION_WARNING_CODENAME.CARD_UNIDENTIFIABLE]: 'cardUnidentifiable',
  [TRANSACTION_WARNING_CODENAME.STATEMENT_WITHIN_SUBMITTED_REPORT]: 'withinSubmittedReport',
  [TRANSACTION_WARNING_CODENAME.PAYMENT_METHOD_NOT_FOUND]: 'methodNotFound',
  [TRANSACTION_WARNING_CODENAME.MULTIPLE_PAYMENT_METHODS_FOUND]: 'multipleMethodsFound'
}


export enum TRANSACTION_ACTIONS {
  DELETE = 'DELETE',
  ADD_REPORT = 'ADD_REPORT',
  REMOVE_FROM_REPORT = 'REMOVE_FROM_REPORT',
  ADD_CARD = 'ADD_CARD',
  SET_DATE = 'SET_DATE',
  UNMATCH = 'UNMATCH',
  MATCH = 'MATCH',
  ADD_COST_WITHOUT_DOCUMENT = 'ADD_COST_WITHOUT_DOCUMENT',
  UNSELECT = 'UNSELECT'
}
