export enum CURRENCY {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD'
}

export enum CURRENCY_SYMBOL {
  EUR = '€',
  GBP = '£',
  USD = '$',
  SEK = 'kr',
  NOK = 'kr',
  PLN = 'zł',
  RUB = '₽'
}

export enum CONVERSION_MODE {
  TRANSACTION_DAY = 'transaction-day',
  PREVIOUS_DAY = 'previous-transaction-day',
  FOLLOWING_WORKDAY = 'first-workday-after-trip'
}
