import { ComputedRef, computed } from 'vue'
import xstore from '@/store'
import { APPROVALS_STORE_ACTIONS, APPROVALS_STORE_GETTERS, APPROVAL_STATE } from '@/enums'
import { useContext, useTrips, useReceipts } from '@/composables'
import { ApprovalItem, Approvals } from '@/models'

export default function useApprovals() {
  const { root } = useContext()
  const { loadTrip } = useTrips()
  const { loadReceipt } = useReceipts()

  const companyId = computed(() => Number(root?.$route.params.companyId))
  const receiptId = computed(() => root?.$route.params.receiptId)
  const tripId = computed(() => root?.$route.params.tripId)

  const allApprovals: ComputedRef<Approvals> = computed(() => {
    if (!companyId.value) return {}
    return xstore.getters[APPROVALS_STORE_GETTERS.APPROVALS][companyId.value] || {}
  })

  const activeTripApproval: ComputedRef<ApprovalItem | undefined> = computed(() => {
    if (!allApprovals.value) return
    return Object.values(allApprovals.value).find((approval) => Number(approval.tripId) == Number(tripId.value))
  })

  const activeReceiptApproval: ComputedRef<ApprovalItem | undefined> = computed(() => {
    if (!allApprovals.value) return
    return Object.values(allApprovals.value).find((approval) => Number(approval.receiptId) == Number(receiptId.value))
  })

  const companyApproverOptions = computed(() => {
    return xstore.getters[APPROVALS_STORE_GETTERS.APPROVER_OPTIONS](companyId.value) || []
  })

  const pendingApprovals: ComputedRef<ApprovalItem[]> = computed(() => {
    return Object.values(allApprovals.value).filter((approval) => {
      return approval.state === APPROVAL_STATE.PENDING
    })
  })

  const archivedApprovals: ComputedRef<ApprovalItem[]> = computed(() => {
    return Object.values(allApprovals.value).filter((approval) => {
      return approval.state !== APPROVAL_STATE.PENDING
    })
  })

  const userActiveDecisions = (userId: string | number | undefined): ApprovalItem[] => {
    if (!userId) return []
    return Object.values(allApprovals.value).filter((approval) => {
      return approval.decisions?.find((decision: any) => {
        return decision.approvingUserId === Number(userId) && decision.state === APPROVAL_STATE.PENDING
      })
    })
  }

  const loadApprovals = (isAdmin: boolean, statuses: APPROVAL_STATE[] = []) => {
    if (!companyId.value) return
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.GET_ALL_APPROVALS, {
      companyId: companyId.value,
      isAdmin,
      statuses
    })
  }

  const loadApproval = (approvalId: string | number) => {
    if (!companyId.value) return
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.GET_APPROVAL, {
      companyId: companyId.value,
      approvalId
    })
  }

  const loadTripApproval = () => {
    if (!companyId.value || !tripId.value) return
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.GET_TRIP_APPROVAL, {
      companyId: companyId.value,
      tripId: tripId.value
    })
  }

  const loadReceiptApproval = () => {
    if (!companyId.value || !receiptId.value) return
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.GET_RECEIPT_APPROVAL, {
      companyId: companyId.value,
      receiptId: receiptId.value
    })
  }

  const deleteApproval = (approvalId: any) => {
    if (!companyId.value || !approvalId) return
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.DELETE_APPROVAL, {
      companyId: companyId.value,
      approvalId
    })
  }

  const loadApproverOptions = async () => {
    if (companyApproverOptions.value && companyApproverOptions.value.length) return
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.GET_APPROVER_OPTIONS, {
      companyId: companyId.value
    })
  }

  const approve = (approvalId: string | number, decisionId: string | number, reason = '') => {
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.APPROVE, {
      companyId: companyId.value,
      approvalId,
      decisionId,
      reason
    })
  }

  const reject = (approvalId: string | number, decisionId: string | number, reason: string) => {
    const payloadData = { reason }
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.REJECT, {
      companyId: companyId.value,
      approvalId,
      decisionId,
      payloadData
    })
  }

  const delegate = (
    approvalId: string | number,
    decisionId: string | number,
    delegateUserId: string | number,
    reason: string
  ) => {
    const payloadData = {
      reason,
      delegateUserId
    }
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.DELEGATE, {
      companyId: companyId.value,
      approvalId,
      decisionId,
      payloadData
    })
  }

  const removeApprover = async (approvalId: string | number, decisionId: string | number) => {
    try {
      await xstore.dispatch(APPROVALS_STORE_ACTIONS.DELETE_DECISION, {
        companyId: companyId.value,
        approvalId,
        decisionId
      })
      root?.$notification('deleted', 'success', 4000)
    } catch (error) {
      root?.$notification(root?.eh(error), 'error', 9000)
    }
  }

  const addDecision = (approvalId: string | number, payloadData: any) => {
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.ADD_DECISION, {
      companyId: companyId.value,
      approvalId,
      payloadData
    })
  }

  const appendApprover = (approvalId: string | number, decisionId: string | number, payloadData: any) => {
    return xstore.dispatch(APPROVALS_STORE_ACTIONS.APPEND, {
      companyId: companyId.value,
      approvalId,
      decisionId,
      payloadData
    })
  }

  const updateStatusAfterApproving = (
    tripId: string | number | undefined,
    receiptId: string | number | undefined,
    comparableStatus: number
  ) => {
    let currentAttempt: number = 0
    let nrOfAttempts: number = 10

    let sendWithRetry = async () => {
      try {
        if (tripId) {
          const trip = await loadTrip(tripId)
          if (trip?.status && trip?.status === comparableStatus) {
            return retry()
          }
          console.log('Success...')
        }
        if (receiptId) {
          const receipt = await loadReceipt(receiptId)
          if (receipt?.status && receipt?.status === comparableStatus) {
            return retry()
          }
          console.log('Success...')
        }
      } catch (error) {
        console.error('ERROR REQUESTING UPDATING STATUS OF APPROVAL SUBJECT', error)
      }
    }

    let retry = () => {
      if (currentAttempt < nrOfAttempts) {
        currentAttempt++
        console.log('Retrying...')
        sendWithRetry()
      } else {
        console.log('Retried updating status several times, status did not change')
      }
    }
    sendWithRetry()
  }

  return {
    allApprovals,
    activeTripApproval,
    activeReceiptApproval,
    archivedApprovals,
    companyApproverOptions,
    pendingApprovals,
    addDecision,
    appendApprover,
    approve,
    reject,
    delegate,
    deleteApproval,
    loadApprovals,
    loadApproval,
    loadApproverOptions,
    loadTripApproval,
    loadReceiptApproval,
    removeApprover,
    updateStatusAfterApproving,
    userActiveDecisions
  }
}
