/**
 * Test that localStorage is available. If writing throws an exception
 * or read value is incorrect, return a dummy object with expected fields
 * that are actually no-op functions.
 */
 interface MockStorage {
  getItem: () => any,
  setItem: () => any,
  removeItem: () => any,
  clear: () => any,
  [key: string]: any
}

const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('testLocalStorageFunctionality', 'true')
    return localStorage.getItem('testLocalStorageFunctionality') === 'true'
  } catch {
    return false
  }
}

const getLocal = (): Storage | MockStorage => {
  return isLocalStorageAvailable()
    ? localStorage
    : {
        getItem: () => {},
        setItem: () => {},
        removeItem: () => {},
        clear: () => {}
      }
}

export default getLocal()
