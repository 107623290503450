import { computed, watch } from 'vue'
import xstore from '@/store'
import { useContext } from '@/composables'

import {
  CONFIG_STORE_GETTERS,

  EVENT_COMPANY_UPDATED,
  EVENT_NEW_DOC,
  EVENT_NEW_REPORT,
  EVENT_EF_STATE_CHANGED,
  EVENT_APPROVAL_STATE_CHANGED,

  COMPANIES_STORE_ACTIONS,
  RECEIPTS_STORE_ACTIONS,
  TRIPS_STORE_ACTIONS,
  APPROVALS_STORE_ACTIONS
} from '@/enums'

export default function useEvents() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId))

  let eventSource: EventSource|null = null;

  const subscribe = (companyId: Number) => {
    eventSource?.close()

    const uri = xstore.getters[CONFIG_STORE_GETTERS.CONFIG]?.services.coreapi.url
      + '/companies/' + companyId + '/eventstream?'
      + 'access_token=' + localStorage.getItem('token')

    eventSource = new EventSource(uri, {
      withCredentials: true
    })

    eventSource.onmessage = (event: MessageEvent) => {
      var ev = JSON.parse(event.data)

      switch(ev.subject) {
        case EVENT_COMPANY_UPDATED:
          xstore.dispatch(COMPANIES_STORE_ACTIONS.GET_COMPANY, ev.data.id)
          break;
        case EVENT_NEW_DOC:
          xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_RECEIPT, {
            companyId,
            receiptId: ev.data.id
          })
          break;
        case EVENT_NEW_REPORT:
          xstore.dispatch(TRIPS_STORE_ACTIONS.GET_TRIP, {
            companyId,
            tripId: ev.data.id
          })

          break;
        case EVENT_EF_STATE_CHANGED:

          if (ev.data.action == 'DELETE') {
            if (ev.data.target === 'EXPENSE_DOCUMENT') {
              xstore.dispatch(RECEIPTS_STORE_ACTIONS.REMOVE_RECEIPT, {
                receiptId: ev.data.receiptId
              })

              if (ev.data.tripId) {
                xstore.dispatch(TRIPS_STORE_ACTIONS.GET_TRIP, {
                  companyId,
                  tripId: ev.data.tripId
                })
              }
            }
            else if (ev.data.target === 'EXPENSE_REPORT') {
              xstore.dispatch(TRIPS_STORE_ACTIONS.REMOVE_TRIP, {
                tripId: ev.data.tripId
              })
            }
            break;
          }

          if (ev.data.receiptId) {
            xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_RECEIPT, {
              companyId,
              receiptId: ev.data.receiptId
            })
          }
          if (ev.data.tripId) {
            xstore.dispatch(TRIPS_STORE_ACTIONS.GET_TRIP, {
              companyId,
              tripId: ev.data.tripId
            })
          }

          break;
        case EVENT_APPROVAL_STATE_CHANGED:
          console.log('received event: ' + EVENT_APPROVAL_STATE_CHANGED + ' (no handler)')

          // TODO get approvals (not part of composables atm)
          // this is only a workaround:
          if (ev.data.receiptId) {
            xstore.dispatch(APPROVALS_STORE_ACTIONS.GET_RECEIPT_APPROVAL, {
              companyId,
              receiptId: ev.data.receiptId
            })
          }
          if (ev.data.tripId) {
            xstore.dispatch(TRIPS_STORE_ACTIONS.GET_TRIP, {
              companyId,
              tripId: ev.data.tripId
            })
          }

          break;
      }
    }

    eventSource.onerror = (err) => {
      console.log(err)
      setTimeout(subscribe, 2000, companyId)
    }
  }

  const initEventStream = () => {
    watch(
      () => companyId.value,
      (id, oldId) => {
        if (id !== undefined && id !== oldId) {
            subscribe(id)
        }
      }
    )
  }

  return {
    initEventStream
  }
}